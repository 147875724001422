import {
  ArButton,
  ArForm,
  ArModal,
  ArModalProps,
  ArTag,
  ButtonTypes,
  FormNode,
  inputType,
  nodeType,
} from "@anarock/ar-common-react";
import { optionProps } from "@anarock/ar-common-react/dist/ui-components/ar-checkbox/index.interface";
import { DeleteOutlined, NotificationOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Rule } from "antd/es/form";
import { useForm, useWatch } from "antd/es/form/Form";
import { useGetRevenueAllocationDetailsQuery, useSubmitRevenueAllocationMutation } from "app/services/reports";
import { useGetBMUsersQuery } from "app/services/wip";
import {
  convertToRupee,
  getValueFromPercentage,
  getValueOrDash,
  isNotNullorUndefined,
} from "app/utils/helperFunctions";
import { useToastService } from "components/toastService";
import { ALLOCATION_STATUS, YesOrNoOptions } from "constants/index";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import ValidationErrors from "constants/ValidationErrors";
import { DisabledTextNode, RequiredNumberNode, RequiredSelectBoxNode } from "features/invoices/components/formHelpers";
import React, { useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import wipStyles from "../../../../wip/index.module.scss";
import { RevenueAllocationConfirmationModal } from "../ConfirmationModals";

interface AllocateRevenueModalProps extends Omit<ArModalProps, "onCancel"> {
  onCancel?: () => void;
  irfProjectId: string;
  profitCenter: string;
}

export const AllocateRevenueFormModal: React.FC<AllocateRevenueModalProps> = ({
  irfProjectId,
  profitCenter,
  ...props
}) => {
  const [form] = useForm();
  const { errorToast, successToast } = useToastService();
  const [openConfirmationModal, setconfirmationModal] = React.useState(false);

  const [commisionArray, setCommisionArray] = React.useState<string[]>([uuidv4()]);
  const [employeeOptions, setEmployeeOptions] = React.useState<optionProps[]>([]);
  const { data: users } = useGetBMUsersQuery(profitCenter, { skip: !props.open });

  const [submitRevenueAllocation, { isLoading }] = useSubmitRevenueAllocationMutation();

  const { data: revenueAllocationDetails, isFetching: isLoadingAllocationDetails } =
    useGetRevenueAllocationDetailsQuery({ irfProjectId, profitCenter });
  const allocatePooledRevenueCase =
    revenueAllocationDetails?.data?.irfAllocation?.irfUserAllocation?.allocationStatus ===
    ALLOCATION_STATUS.POOLED_ALLOCATION;

  const isRevenuePooled = useWatch(FORM_FIELDS.POOL_REVENUE, form) === "Yes";

  const commissionSumRule: Rule = {
    validator: (obj: any, value) => {
      let sum = Number(form?.getFieldValue(FORM_FIELDS.REV_POOL_FOR_PERCENT)) || 0;
      const key = obj?.field?.split(" - ")?.[1];
      const index = commisionArray?.indexOf(key);

      const currValue = Number(value) || 0;
      const prevValue = Number(
        revenueAllocationDetails?.data?.irfAllocation?.irfUserAllocation?.allocation?.[index]?.weightage || 0
      );
      if (currValue && prevValue && currValue < prevValue) {
        return Promise.reject(new Error(ValidationErrors.VALUE_LESS_THAN + prevValue));
      }

      for (let i = 0; i < commisionArray?.length; i++) {
        sum += Number(form?.getFieldValue(`${FORM_FIELDS.COMMISSION_PERCENT} - ${commisionArray[i]}`)) || 0;
      }
      if (sum != 100) {
        return Promise.reject(new Error(ValidationErrors.TOTAL_ALL_100));
      }

      return Promise.resolve();
    },
    validateTrigger: "onBlur",
  };

  const handleSubmitModal = async () => {
    setconfirmationModal(false);

    let response;
    try {
      response = await submitRevenueAllocation({
        irfProjectId: irfProjectId,
        profitCenter,
        allocation: commisionArray?.map((item) => {
          return {
            userId: form.getFieldValue(`${FORM_FIELDS.EMPLOYEE} - ${item}`),
            userName:
              users?.data?.brandManagerUsers?.find(
                ({ userId }) => userId === form.getFieldValue(`${FORM_FIELDS.EMPLOYEE} - ${item}`)
              )?.userName || "",
            weightage: form.getFieldValue(`${FORM_FIELDS.COMMISSION_PERCENT} - ${item}`),
          };
        }),
        pooledRevenuePercentage: isRevenuePooled ? form.getFieldValue(FORM_FIELDS.REV_POOL_FOR_PERCENT) : 0,
        allocationStatus:
          !isRevenuePooled || allocatePooledRevenueCase
            ? ALLOCATION_STATUS.FULLY_ALLOCATED
            : ALLOCATION_STATUS.POOLED_ALLOCATION,
      })?.unwrap();
    } catch (e) {
      errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
    if (response?.success) {
      successToast(STRING_CONSTANTS.POOL_REVENUE_UPDATED);
      props?.onCancel?.();
    } else {
      errorToast(response?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
  };

  const handleFieldChange = (field: any) => {
    handleChange(field[0]?.name?.[0]);
    const fieldName = field[0]?.name?.[0];
    const fieldValue = Number(field[0]?.value || 0);
    if (fieldName === FORM_FIELDS.REV_POOL_FOR_PERCENT) {
      form.setFieldValue(
        `${STRING_CONSTANTS.AMOUNT} - ${FORM_FIELDS.REV_POOL_FOR_PERCENT}`,
        convertToRupee(
          getValueFromPercentage(fieldValue, revenueAllocationDetails?.data?.irfAllocation?.netRevenue || 0)
        )
      );
    }
    if (!fieldName?.includes(FORM_FIELDS.COMMISSION_PERCENT)) {
      return;
    }

    const key = fieldName?.split(" - ")?.[1];
    const finalAmount = getValueFromPercentage(
      fieldValue,
      revenueAllocationDetails?.data?.irfAllocation?.netRevenue || 0
    );
    form.setFieldValue(`${STRING_CONSTANTS.AMOUNT} - ${key}`, convertToRupee(finalAmount));
  };

  const handleChange = useCallback(
    (fieldName: string) => {
      if (!fieldName?.includes(FORM_FIELDS.EMPLOYEE)) {
        return;
      }
      const currentUsedPcs = commisionArray?.map((id) => form.getFieldValue(`${FORM_FIELDS.EMPLOYEE} - ${id}`));

      const list = users?.data?.brandManagerUsers;
      const newList =
        list?.map(({ userName, userId }) => ({
          label: userName,
          value: userId,
          disabled: currentUsedPcs?.includes(userId),
        })) || [];
      setEmployeeOptions(newList);
    },
    [commisionArray, users?.data?.brandManagerUsers, form]
  );

  const PoolRevenueNode: FormNode = {
    type: nodeType.container,
    elementData: {
      title: FORM_FIELDS.POOL_REVENUE,
      titleClassname: ` formtitle sub-hero-text py-16 `,
      innerContainerClassName: `${wipStyles.reqInnerContainer} ${wipStyles.revenueAllocationContainer}  `,
    },
    childNode: {
      type: nodeType.array,
      childNode: [
        RequiredSelectBoxNode(FORM_FIELDS.POOL_REVENUE, FORM_FIELDS.POOL_REVENUE, YesOrNoOptions, "No"),
        ...(isRevenuePooled
          ? [
              RequiredNumberNode(
                FORM_FIELDS.REV_POOL_FOR_PERCENT,
                FORM_FIELDS.REV_POOL_FOR_PERCENT,
                undefined,
                undefined,
                [commissionSumRule]
              ),
              DisabledTextNode(
                `${STRING_CONSTANTS.AMOUNT} - ${FORM_FIELDS.REV_POOL_FOR_PERCENT}`,
                STRING_CONSTANTS.AMOUNT,
                convertToRupee(
                  getValueFromPercentage(
                    revenueAllocationDetails?.data?.irfAllocation?.irfUserAllocation?.pooledRevenuePercentage || 0,
                    revenueAllocationDetails?.data?.irfAllocation?.netRevenue || 0
                  )
                ),
                undefined,
                false
              ),
            ]
          : []),
      ],
    },
  };

  const deleteNode = (idx: number) => {
    const arr = [...commisionArray];
    arr.splice(idx, 1);
    setCommisionArray(arr);
  };

  const FinalAllocationNode: FormNode = {
    type: nodeType.container,
    elementData: {
      title: allocatePooledRevenueCase
        ? `Allocated Revenue (${getValueOrDash(revenueAllocationDetails?.data?.irfAllocation?.allocatedRevenuePerc)}%)`
        : STRING_CONSTANTS.REVENUE_ALLOCATION,
      titleClassname: ` formtitle sub-hero-text pb-16 ${allocatePooledRevenueCase ? `pt-16` : "pt-8"}`,
      innerContainerClassName: `${wipStyles.reqInnerContainer} ${wipStyles.revenueAllocationContainer}`,
    },
    childNode: {
      type: nodeType.array,
      childNode: Array.from({ length: commisionArray?.length }, (_, index) => [
        RequiredSelectBoxNode(
          `${FORM_FIELDS.EMPLOYEE} - ${commisionArray[index]}`,
          `${FORM_FIELDS.EMPLOYEE} - ${index + 1}`,
          employeeOptions,
          revenueAllocationDetails?.data?.irfAllocation?.irfUserAllocation?.allocation?.[index]?.userId,
          undefined,
          undefined,
          isNotNullorUndefined(
            revenueAllocationDetails?.data?.irfAllocation?.irfUserAllocation?.allocation?.[index]?.userId
          )
        ),
        RequiredNumberNode(
          `${FORM_FIELDS.COMMISSION_PERCENT} - ${commisionArray[index]}`,
          `${FORM_FIELDS.COMMISSION_PERCENT}`,
          String(revenueAllocationDetails?.data?.irfAllocation?.irfUserAllocation?.allocation?.[index]?.weightage || 0),
          undefined,
          [commissionSumRule],
          undefined
        ),
        DisabledTextNode(
          `${STRING_CONSTANTS.AMOUNT} - ${commisionArray[index]}`,
          STRING_CONSTANTS.AMOUNT,
          convertToRupee(
            getValueFromPercentage(
              revenueAllocationDetails?.data?.irfAllocation?.irfUserAllocation?.allocation?.[index]?.weightage || 0,
              revenueAllocationDetails?.data?.irfAllocation?.netRevenue || 0
            )
          ),
          undefined,
          false
        ),

        {
          type: nodeType.input,
          elementData: {
            inputType: inputType.button,
            inputProps: {
              type: ButtonTypes.Link,
              children: <DeleteOutlined onClick={() => deleteNode(index)} />,
              disabled:
                index === 0 ||
                isNotNullorUndefined(
                  revenueAllocationDetails?.data?.irfAllocation?.irfUserAllocation?.allocation?.[index]?.userId
                ),
            },
          },
        },
      ])?.flat(),
    },
  };

  const addMore = () => {
    if (commisionArray.length >= 10) {
      return;
    }
    const arr = [...commisionArray];
    arr.push(uuidv4());
    setCommisionArray(arr);
  };

  const AddButtonNode: FormNode = {
    type: nodeType.input,
    elementData: {
      inputType: inputType.button,
      formItemProps: {
        // name: STRING_CONSTANTS.ADD_commercial_BUTTON,
      },
      inputProps: {
        type: ButtonTypes.Link,
        children: (
          <>
            <PlusSquareOutlined /> {STRING_CONSTANTS.ADD_MORE_EMP_ALLOCATION}
          </>
        ),
        size: "large",
        disabled: commisionArray.length >= 10,
        onClick: addMore,

        className: "pb-16",
      },
    },
  };

  const RevenueAllocationNode: FormNode = {
    type: nodeType.array,
    childNode: allocatePooledRevenueCase
      ? [FinalAllocationNode, AddButtonNode]
      : [PoolRevenueNode, FinalAllocationNode, AddButtonNode],
  };

  const checkValidationandSubmit = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }
    setconfirmationModal(true);
  };
  useEffect(() => {
    if (props?.open) {
      form.resetFields();
    }
  }, [props.open, form]);

  useEffect(() => {
    if (props?.open && revenueAllocationDetails?.data?.irfAllocation?.irfUserAllocation?.allocation?.length) {
      setCommisionArray(
        Array.from(
          { length: revenueAllocationDetails?.data?.irfAllocation?.irfUserAllocation?.allocation?.length },
          () => uuidv4()
        )
      );
    }
  }, [props?.open, revenueAllocationDetails?.data?.irfAllocation?.irfUserAllocation?.allocation]);

  useEffect(() => {
    if (users?.data?.brandManagerUsers?.length) {
      handleChange(FORM_FIELDS.EMPLOYEE);
    }
  }, [users?.data?.brandManagerUsers?.length, handleChange]);

  return (
    <ArModal
      width={956}
      {...props}
      showBorderForHeader
      footer={
        <div className="modal-btn-container">
          <ArButton
            type={ButtonTypes.Secondary}
            onClick={() => {
              props?.onCancel?.();
            }}
          >
            {STRING_CONSTANTS.CANCEL}
          </ArButton>
          <ArButton
            type={ButtonTypes.Primary}
            loading={isLoading}
            onClick={() => {
              checkValidationandSubmit();
            }}
          >
            {allocatePooledRevenueCase ? STRING_CONSTANTS.ALLOCATE_POOLED_REVENUE : STRING_CONSTANTS.UPDATE_ALLOCATION}
          </ArButton>
        </div>
      }
      destroyOnClose
      title={`Revenue Allocation for ${convertToRupee(
        getValueOrDash(revenueAllocationDetails?.data?.irfAllocation?.netRevenue) as number
      )}  for ${getValueOrDash(revenueAllocationDetails?.data?.irfAllocation?.clientName)}`}
    >
      <RevenueAllocationConfirmationModal
        open={openConfirmationModal}
        onCancel={() => setconfirmationModal(false)}
        onSubmit={() => handleSubmitModal()}
        closeModal={() => setconfirmationModal(false)}
      />
      {isLoadingAllocationDetails ? (
        <Spin size="large" />
      ) : (
        <div className="listModal">
          {allocatePooledRevenueCase ? (
            <ArTag className="mb-16" type="full-width" varient={"warning"}>
              <NotificationOutlined />{" "}
              <b>
                {STRING_CONSTANTS.ALLOCATE_POOLED_REVENUE} (
                {revenueAllocationDetails?.data?.irfAllocation?.pooledRevenuePercentage || 0}%)
              </b>{" "}
              {STRING_CONSTANTS.PLEASE_ALLOCATE_ALL_REVENUE}
            </ArTag>
          ) : null}
          <ArForm form={form} formNode={RevenueAllocationNode} onFieldsChange={handleFieldChange} />
        </div>
      )}
    </ArModal>
  );
};
