import { FormNode, nodeType } from "@anarock/ar-common-react";
import { ContactInfo } from "@anarock/ar-common-react/dist/ui-components/ar-brand-modals/index.interface";
import { optionProps } from "@anarock/ar-common-react/dist/ui-components/ar-checkbox/index.interface";
import { ILocation, INewLandLord, IPhoneNumber } from "app/services/createRequirmentApi";
import { IEnum } from "app/services/enums";
import { IIRF } from "app/services/invoices";
import { IMatchingShopDetail } from "app/services/matchingPropertiesApi";
import { IOptionListShop } from "app/services/optionListApi";
import { IShopDetails } from "app/services/shopDetailsApi";
import { IShopListing } from "app/services/shopListingApi";
import { IWipBody } from "app/services/wip";
import {
  gethelperTextByCountAndLabelProps,
  IKeyValuePairForDetails,
} from "app/utils/interfaces/helperFunctionInterfaces";
import { TABLE_CONSTANTS } from "constants/helperStrings";
import {
  FEE_CALCULATION_BASE_1,
  FIXED_RENT_BASE_OPTIONS,
  PURE_REV_SHARING_OPTIONS,
  STAGGERED_RENT_OPTIONS,
} from "constants/index";
import { UNITS } from "constants/ListingEnums";
import { FYMonthsArr, monthsArr } from "constants/months";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { IMarketMap, IShopImages, IShopPocDetails } from "constants/ShopStructure";
import { ShopHistoryItem } from "constants/ShopStructure";
import { STRING_CONSTANTS } from "constants/StringConstants";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numWords from "num-words";
import React from "react";
import validator from "validator";

import styles from "../../global.module.scss";

export const getCompressedString = (value: string[] | undefined, limit?: number) => {
  const valueArray = value?.filter((item) => value.indexOf(item) < (limit ? limit : 2));
  if (!value) return value;
  else if (value.length <= (limit ? limit : 2)) return valueArray?.join(", ");
  else {
    return `${valueArray?.join(", ")} ...+${value.length - (limit ? limit : 2)} `;
  }
};
export const contactNumberToString = (contactNumbers: IPhoneNumber[]) => {
  const filteredArr = contactNumbers?.filter((item) => item?.number);
  return filteredArr?.length ? filteredArr?.map((item) => `${item.countryCode || "+91"} ${item.number}`) : ["-"];
};
export const getPhoneNumberString = (item: IPhoneNumber) => `${item?.countryCode || "+91"} ${item?.number}`;

export const getContactPerson = (contactPerson: IShopPocDetails) => {
  const { name, email, phoneNumber, alternatePhoneNumbers, relationship } = contactPerson;
  const contactPersonProp: ContactInfo = {
    name,
    designation: relationship,
    contactInfo: {
      email,
      phone: contactNumberToString([phoneNumber, ...alternatePhoneNumbers]),
    },
  };
  return contactPersonProp;
};

export const integerToCommaString = (value: number) => {
  const val = getValueOrDash(value);
  return val?.toLocaleString("en-IN");
};

export const getRent = (rent: number, duration?: string) => {
  const rentValue = getValueOrDash(integerToCommaString(rent));

  return `${UNITS.SYMBOL_RUPEES}${rentValue}${duration || ""}`;
};

export const toDate = (date: string) => {
  return moment(date).toDate().toDateString();
};

export const getAllContactPersons = (shop: IShopListing | IMatchingShopDetail | IShopDetails) => {
  const { surveyor, poc } = shop;
  const allContactPersons = [];
  if (surveyor) {
    allContactPersons.push(
      getContactPerson({
        ...surveyor,
        relationship: STRING_CONSTANTS.SURVEYOR,
        alternatePhoneNumbers: [],
      })
    );
  }
  poc?.forEach((item) => {
    allContactPersons.push(getContactPerson(item));
  });
  return allContactPersons;
};

export const getShopIdsFromOptionList = (options: IOptionListShop[]) => {
  return options.map((item) => item.shop._id);
};

export const getShopHistoryString = (shopHistoryDoc: ShopHistoryItem) => {
  const { brandManager, status, brand } = shopHistoryDoc;
  return `${getValueOrDash(brandManager?.name)} ${STRING_CONSTANTS.MOVED_THIS_SHOP_TO} ${getValueOrDash(status)} ${
    STRING_CONSTANTS.OF
  } ${getValueOrDash(brand?.brandName)}`;
};

export const nextLocalities = (LocalityList: ILocation[], localities: string[]) => {
  const nextLocalitiesId = localities?.map((locality: string) => {
    const localityObj = LocalityList?.find((item) => item.name === locality);
    return localityObj?._id || "";
  });
  return nextLocalitiesId;
};

export const getLocality = (childNode: FormNode[]) => {
  return {
    type: nodeType.container,
    elementData: {
      description: "Locality",
      descriptionClassname: styles.subHeading,
      innerContainerClassName: styles.reqInnerContainer,
    },
    childNode: {
      type: nodeType.array,
      childNode,
    },
  };
};

export const getReqForm = (childNode: FormNode[], title: string) => {
  return {
    type: nodeType.container,
    elementData: {
      title,
      titleClassname: styles.formTitle,
    },
    childNode: {
      type: nodeType.array,
      childNode,
    },
  };
};

export const getFormLayout = (childNode: FormNode[]) => {
  return {
    type: nodeType.container,
    elementData: {
      outerContainerClassName: styles.reqOuterContainer,
    },
    childNode: {
      type: nodeType.array,
      childNode,
    },
  };
};

export const getShopImages = (images: IShopImages, marketMap?: IMarketMap) => {
  const shopImages = [
    ...(images?.frontView
      ?.filter((item) => item?.url && item?.url?.length)
      ?.map((item) => {
        return { url: item?.url, description: STRING_CONSTANTS.FRONT_VIEW };
      }) || []),
    ...(images?.leftView
      ?.filter((item) => item?.url && item?.url?.length)
      ?.map((item) => {
        return { url: item?.url, description: STRING_CONSTANTS.LEFT_VIEW };
      }) || []),
    ...(images?.rightView
      ?.filter((item) => item?.url && item?.url?.length)
      ?.map((item) => {
        return { url: item?.url, description: STRING_CONSTANTS.RIGHT_VIEW };
      }) || []),
    ...(images?.vicinityView
      ?.filter((item) => item?.url && item?.url?.length)
      ?.map((item) => {
        return { url: item?.url, description: STRING_CONSTANTS.VICINITY_VIEW };
      }) || []),
    ...(images?.optional
      ?.filter((item) => item?.url && item?.url?.length)
      ?.map((item) => {
        return { url: item?.url, description: STRING_CONSTANTS.OPTIONAL };
      }) || []),
  ];
  if (!isNullOrUndefiend(marketMap) && !isNullOrUndefiend(marketMap?.url) && marketMap?.url?.length) {
    shopImages.push({
      url: marketMap?.url || "",
      description: STRING_CONSTANTS.MARKET_MAP,
    });
  }
  if (shopImages.length === 0) shopImages.push({ url: "", description: STRING_CONSTANTS.NO_IMAGE });
  return shopImages;
};

export const gethelperTextByCountAndLabel = (helperTextArray: gethelperTextByCountAndLabelProps[]) => {
  return helperTextArray?.map((helperObject) => `${helperObject?.value?.length} ${helperObject?.label}`).join(" ");
};
// function to convert past time to relative time in shortened fromat
export const shortenRelativeTime = (time: string) => {
  const units = ["s", "m", "h", "d", "w", "mo", "y"];
  const thresholds = [50, 60, 60, 24, 7, 4, 12];
  let value = Math.abs(moment.duration(moment().diff(time)).as("m"));
  let unitIndex = 0;
  while (value >= thresholds[unitIndex] && unitIndex < thresholds.length) {
    value /= thresholds[unitIndex];
    unitIndex++;
  }
  const roundedValue = Math.round(value);
  const unit = units[unitIndex];

  return `${roundedValue}${unit}`;
};

export const isNotNullorUndefined = (value: unknown) => {
  return !(value === undefined || value === null);
};

export const isNullOrUndefiend = (value: unknown) => {
  return value === null || value === undefined;
};

export const isNullOrUndefiendOrEmpty = (value?: string | number | null) => {
  return value === null || value === undefined || value === "";
};

export const isObjectNullOrUndefiendOrEmpty = (value?: object) => {
  return value === null || value === undefined || JSON.stringify(value) === JSON.stringify({});
};

export const isStringEmpty = (value: string) => {
  return isNullOrUndefiend(value) || value.length === 0 || value === "";
};

export const isArrayEmpty = (value: Array<any>) => {
  return isNullOrUndefiend(value) && value?.length === 0;
};

export const debounce = <T extends (...args: any[]) => ReturnType<T>>(
  callback: T,
  timeout: number
): ((...args: Parameters<T>) => void) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};
export const openSurveyorApp = () => {
  const androidPackageName = "com.anarockretail";

  const iosAppStoreID = 6449526743;

  // Create the Intent URL for Android
  const androidIntentURL = `intent://${androidPackageName}#Intent;scheme=myapp;package=${androidPackageName};end;`;

  // Create the App Store URL for iOS
  const iosAppStoreURL = `itms-apps://apps.apple.com/app/id${iosAppStoreID}`;

  // Attempt to open the app using App Links for Android
  // If the app is not installed on Android, redirect to the App Store URL for iOS
  const openLink = function (url: string) {
    window.location.href = url;
  };

  if (navigator.userAgent.match(/Android/i)) {
    openLink(androidIntentURL);
  } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    openLink(iosAppStoreURL);
  } else {
    // Handle other platforms or show a message to the user
    alert("This feature is not supported on your device. Please go to the shop connect app and login.");
  }
};
export const getValueOrDash = (val: string | null | undefined | number) => {
  const value = isNullOrUndefiendOrEmpty(val) ? "-" : val;
  return value;
};

export const convertToRupee = (value: number) => {
  if (isNullOrUndefiend(value) || isNaN(value)) {
    return "₹0";
  }
  const val = getFixedDigit(Number(value));
  if (val < 0) {
    return "-₹" + (val * -1)?.toLocaleString("en-IN");
  }
  return "₹" + val?.toLocaleString("en-IN");
};

export const convertEnumsToRadioOptions = (
  object?: {
    [key: string]: string;
  },
  hideUptilIdx?: number
): optionProps[] => {
  const idx = hideUptilIdx || 0;
  const arr = [];
  for (const key in object) {
    const obj = {
      label: object[key],
      value: object[key],
    };
    arr.push(obj);
  }
  return arr.slice(idx);
};

export const singleDayRent = (rent?: number) => {
  if (!rent) {
    return 0;
  }
  const singleDayRent = rent / 30 || 0;
  return singleDayRent;
};
export const getRentForDays = (rent?: number, days?: number) => {
  const rentPerDay = singleDayRent(rent);
  const calculatedRent = rentPerDay * (days || 0);
  return calculatedRent;
};

export const getDaysForRent = (rent?: number, totalAmount?: number) => {
  const rentPerDay = singleDayRent(rent);
  const days = (totalAmount || 0) / rentPerDay;
  return days;
};
export const getFixedDigit = (number: number, digit?: number) => {
  return Number(number?.toFixed(digit || 2));
};

export const getFixedDaysForRent = (rent?: number, totalAmount?: number) => {
  const days = getDaysForRent(rent, totalAmount);
  return getFixedDigit(days);
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
  return numericPhoneNumber.length === 10;
};

export const runWipValidations = (wipObject: IWipBody) => {
  const { landlord, commercialDetails, brokerageDetails, brand } = wipObject;
  // @ts-ignore
  const brandPoc = [...(brand?.poc || [])];

  const isIndependentLeasingWIP = !(wipObject?.property?.shopId || wipObject?.property?.mall?._id);

  if (!isIndependentLeasingWIP && (!landlord || landlord?.length === 0)) {
    return { idx: 0, validated: false };
  }
  if (!isIndependentLeasingWIP && landlord) {
    for (let i = 0; i < landlord?.length; i++) {
      const { pocIds, landlordId } = landlord[i];

      if (isNullOrUndefiendOrEmpty(landlordId) || !pocIds?.length || pocIds?.length < 1) {
        return { idx: 0, validated: false };
      }
    }
  }

  if (!brandPoc || brandPoc?.length === 0) {
    return { idx: 0, validated: false };
  }
  if (brandPoc) {
    for (let i = 0; i < brandPoc?.length; i++) {
      const { name, email, phoneNumber } = brandPoc[i];

      if (
        isNullOrUndefiendOrEmpty(name) ||
        !validator.isEmail(email) ||
        !isValidPhoneNumber(phoneNumber?.number?.toString())
      ) {
        console.log(
          isNullOrUndefiendOrEmpty(name),
          !validator.isEmail(email),
          !isValidPhoneNumber(phoneNumber?.number?.toString())
        );
        return { idx: 0, validated: false };
      }
    }
  }

  if (!commercialDetails) {
    return { idx: 1, validated: false };
  }
  if (isNullOrUndefiendOrEmpty(commercialDetails?.area?.value)) {
    return { idx: 1, validated: false };
  }
  if (!brokerageDetails || brokerageDetails?.length === 0) {
    return { idx: 1, validated: false };
  }

  if (brokerageDetails) {
    let error = false;
    brokerageDetails?.forEach((item) => {
      const { fy, shopBrokerage, brandBrokerage } = item;
      if (
        isNullOrUndefiendOrEmpty(fy) ||
        isNullOrUndefiendOrEmpty(shopBrokerage?.current) ||
        isNullOrUndefiend(brandBrokerage?.current)
      ) {
        error = true;
      }
    });
    if (error) {
      return { idx: 1, validated: false };
    }

    brokerageDetails?.forEach((item) => {
      const { monthlyBrokerageDetails, shopBrokerage, brandBrokerage } = item;

      if (!monthlyBrokerageDetails || monthlyBrokerageDetails?.length === 0) {
        return { idx: 2, validated: false };
      }
      let llAmount = 0;
      let brandAmount = 0;
      monthlyBrokerageDetails?.forEach((item) => {
        const { shopBrokerage, brandBrokerage } = item;
        llAmount += Number(shopBrokerage?.current);
        brandAmount += Number(brandBrokerage?.current);

        if (isNullOrUndefiendOrEmpty(shopBrokerage?.current) || isNullOrUndefiendOrEmpty(brandBrokerage?.current)) {
          error = true;
        }
      });
      if (brandAmount !== Number(brandBrokerage?.current) || llAmount !== Number(shopBrokerage?.current)) {
        error = true;
      }
    });
    if (error) {
      return { idx: 2, validated: false };
    }
  }

  return { idx: -1, validated: true };
};
export const runConsultingWipValidations = (wipObject: IWipBody, billingClient?: string) => {
  const { brand, landlord, brokerageDetails } = wipObject;
  // @ts-ignore
  const brandPoc = [...(brand?.poc || [])];
  const landlords = [...(landlord || [])];

  if (billingClient === STRING_CONSTANTS.BRAND) {
    if (!brandPoc || brandPoc?.length === 0) {
      return { idx: 0, validated: false };
    }
    if (brandPoc) {
      for (let i = 0; i < brandPoc?.length; i++) {
        const { name, email, phoneNumber } = brandPoc[i];

        if (
          isNullOrUndefiendOrEmpty(name) ||
          !validator.isEmail(email) ||
          !isValidPhoneNumber(phoneNumber?.number?.toString())
        ) {
          return { idx: 0, validated: false };
        }
      }
    }
  } else if (billingClient === STRING_CONSTANTS.LANDLORD) {
    if (!landlords || landlords?.length === 0) {
      return { idx: 0, validated: false };
    }
    if (landlords) {
      for (let i = 0; i < landlords?.length; i++) {
        const { pocIds, landlordId } = landlords[i];

        if (isNullOrUndefiendOrEmpty(landlordId) || !pocIds?.length || pocIds?.length < 1) {
          return { idx: 0, validated: false };
        }
      }
    }
  } else {
    if (!brandPoc || brandPoc?.length === 0 || !landlords || landlords?.length === 0) {
      return { idx: 0, validated: false };
    }
    if (brandPoc) {
      for (let i = 0; i < brandPoc?.length; i++) {
        const { name, email, phoneNumber } = brandPoc[i];

        if (
          isNullOrUndefiendOrEmpty(name) ||
          !validator.isEmail(email) ||
          !isValidPhoneNumber(phoneNumber?.number?.toString())
        ) {
          return { idx: 0, validated: false };
        }
      }
    }
    if (landlords) {
      for (let i = 0; i < landlords?.length; i++) {
        const { pocIds, landlordId } = landlords[i];

        if (isNullOrUndefiendOrEmpty(landlordId) || !pocIds?.length || pocIds?.length < 1) {
          return { idx: 0, validated: false };
        }
      }
    }
  }

  if (!brokerageDetails || brokerageDetails?.length === 0) {
    return { idx: 1, validated: false };
  }

  if (brokerageDetails) {
    let error = false;
    brokerageDetails?.forEach((item) => {
      const { fy, shopBrokerage, brandBrokerage } = item;
      if (
        isNullOrUndefiendOrEmpty(fy) ||
        isNullOrUndefiend(shopBrokerage?.current) ||
        isNullOrUndefiend(brandBrokerage?.current)
      ) {
        error = true;
      }
    });
    if (error) {
      return { idx: 1, validated: false };
    }

    brokerageDetails?.forEach((item) => {
      const { monthlyBrokerageDetails, shopBrokerage, brandBrokerage } = item;

      if (!monthlyBrokerageDetails || monthlyBrokerageDetails?.length === 0) {
        return { idx: 2, validated: false };
      }
      let llAmount = 0;
      let brandAmount = 0;
      monthlyBrokerageDetails?.forEach((item) => {
        const { shopBrokerage, brandBrokerage } = item;
        llAmount += Number(shopBrokerage?.current);
        brandAmount += Number(brandBrokerage?.current);

        if (billingClient === STRING_CONSTANTS.BRAND && isNullOrUndefiendOrEmpty(brandBrokerage?.current)) {
          error = true;
        } else if (billingClient === STRING_CONSTANTS.LANDLORD && isNullOrUndefiendOrEmpty(shopBrokerage?.current)) {
          error = true;
        } else if (
          billingClient === STRING_CONSTANTS.LL_N_BARND &&
          (isNullOrUndefiendOrEmpty(shopBrokerage?.current) || isNullOrUndefiendOrEmpty(brandBrokerage?.current))
        ) {
          error = true;
        }
      });
      if (brandAmount !== Number(brandBrokerage?.current) || llAmount !== Number(shopBrokerage?.current)) {
        error = true;
      }
    });
    if (error) {
      return { idx: 2, validated: false };
    }
  }

  return { idx: -1, validated: true };
};

export const convertEnumsValuesToArray = (object?: IEnum) => {
  return Object.values(object || {});
};

export const convertBillingMilestoneValuesToArray = (object?: IEnum) => {
  return Object.keys(object || {}).map((key) => ({
    key: key,
    value: (object || {})[key],
  }));
};

export const getFormattedTimeString = (val: string) => {
  const hours = Math.floor(moment.duration(moment().diff(val)).asHours());

  if (hours < 0) {
    STRING_CONSTANTS.ZERO_HOUR_AGO;
  }
  if (hours < 2) {
    return `${hours} ${STRING_CONSTANTS.HOUR_AGO}`;
  }

  if (hours <= 48) {
    return `${hours}  ${STRING_CONSTANTS.HOURS_AGO}`;
  }

  const days = moment.duration(moment().diff(val)).asDays();
  if (days <= 30) {
    return `${Math.floor(days)}  ${STRING_CONSTANTS.DAYS_AGO}`;
  }
  const date = moment(val).format("DD/MM/YYYY");
  return `${STRING_CONSTANTS.ON} ${date}`;
};

export const getTruncatedString = (str: string, length: number) => {
  return str?.length > length ? `${str?.substring(0, 12)}..` : str;
};

export const getTableAlign = (val?: string | number) => {
  return isNullOrUndefiend(val) || String(val).length === 0 || isNaN(Number(val))
    ? TABLE_CONSTANTS.LEFT
    : TABLE_CONSTANTS.RIGHT;
};

export const getPercentage = (numerator: number, denominator: number) => {
  if (isNullOrUndefiend(numerator) || isNullOrUndefiend(denominator)) {
    return "0%";
  }
  if (denominator == 0) {
    return "0%";
  }
  return getFixedDigit((numerator / denominator) * 100) + "%";
};

export const getValueFromPercentage = (percentage: number, value: number) => {
  if (isNullOrUndefiend(value) || isNullOrUndefiend(percentage)) {
    return 0;
  }

  return getFixedDigit((percentage / 100) * value);
};

export const convertToPercent = (numerator: number, denominator: number) => {
  if (isNullOrUndefiend(numerator) || isNullOrUndefiend(denominator)) {
    return 0;
  }
  const denominatorModified = denominator === 0 ? 1 : denominator;
  return getFixedDigit((numerator / denominatorModified) * 100);
};

export const convertToCrore = (number?: number) => {
  if (!number) {
    return "-";
  }

  return getFixedDigit(number / 10000000) + " Cr";
};

export const getFiltersForRequirement = (
  brandId?: string,
  cityId?: string,
  localities?: string[],
  requirementStatus?: string[]
) => {
  const filters = {
    BRAND: [brandId],
    CITY: [cityId],
    LOCALITY: localities,
    REQUIREMENT_STATUS: requirementStatus,
  };
  return JSON.stringify(filters);
};

export const getMonthKeysForCurrentFY = () => {
  const currentYearKey = moment().subtract(3, "M").format("YYYY");
  const startMonth = moment(`04-${currentYearKey}`, "MM-YYYY");
  const monthKeysArr = [];
  while (monthKeysArr.length !== 12) {
    const startMonthKey = startMonth.format("MM-YYYY");
    startMonth.add(1, "M");
    monthKeysArr.push(startMonthKey);
  }
  return monthKeysArr;
};

export const getFormattedDateOrDash = (val: string | undefined | null) => {
  if (isNullOrUndefiend(val)) return "-";
  const date = moment(val).format("DD/MM/YYYY");

  return getValueOrDash(date);
};

export const getCurrentFinancialYear = () => {
  const today = new Date();
  const currentMonth = today.getMonth(); // 0-indexed

  let startYear, endYear;

  if (currentMonth >= 3) {
    startYear = today.getFullYear();
    endYear = startYear + 1;
  } else {
    endYear = today.getFullYear();
    startYear = endYear - 1;
  }

  return `FY ${startYear.toString()}-${endYear.toString().substring(2, 4)}`;
};

export const getCurrentMonth = () => {
  const currentMonth = moment().format("MMMM");
  return currentMonth;
};
export const getCurrentMonthKey = () => {
  const currentMonth = moment().format("MM-YYYY");
  return currentMonth;
};

export const openURLInNewTab = (url: string) => {
  window.open(url, "_blank");
};

export const getCurrentMonthIdxForFy = () => {
  const currMonth = getCurrentMonth();
  const idx = FYMonthsArr.findIndex((val) => val === currMonth);
  return idx < 0 ? 0 : idx;
};

export const convertArrayToRadioOptions = (
  array?: string[],
  hideUptilIdx?: number,
  hideFromIdx?: number
): optionProps[] => {
  const startIdx = hideUptilIdx || 0;
  const endIdx = hideFromIdx || array?.length;

  return (
    array?.slice(startIdx, endIdx)?.map((value) => {
      return {
        label: value,
        value,
      };
    }) || []
  );
};

export const convertLLAutoSuggestArrayToRadioOptions = (array?: INewLandLord[]): optionProps[] => {
  return (
    array?.map(({ legalEntityName, name, industry, _id, highlight }) => {
      return {
        label: (
          <div className="py-8">
            <p>{`${legalEntityName || name} || ${getValueOrDash(industry)}`}</p>
            <p
              className="sub-hero-text-regular-medium pt-4 bold"
              dangerouslySetInnerHTML={{ __html: highlight?.[0] || "-" }}
            ></p>
          </div>
        ),
        value: _id || "",
      };
    }) || []
  );
};

export const convertLLArrayToRadioOptions = (array?: INewLandLord[]): optionProps[] => {
  return (
    array?.map(({ legalEntityName, name, industry, _id, email, phoneNumber }) => {
      return {
        label: (
          <div className="py-8">
            <p>{`${legalEntityName || name} || ${getValueOrDash(industry)}`}</p>
            <p className="sub-hero-text-regular-medium pt-4 bold">{`${getValueOrDash(email)} || ${
              phoneNumber ? getPhoneNumberString(phoneNumber) : "-"
            }`}</p>
          </div>
        ),
        value: _id || "",
      };
    }) || []
  );
};

export const getCurrentYearMonthsWithTentative = () => {
  const currentYear = moment().format("YYYY");
  const currMonth = getCurrentMonth();
  const currMonthIdx = monthsArr?.findIndex((mon) => mon === currMonth);
  const modifiedArr = [...monthsArr];
  modifiedArr.splice(currMonthIdx + 1, 0, currMonth + " Tentative");
  const arrayWithYear = modifiedArr.map((element) => element + " " + currentYear);

  return arrayWithYear;
};

export const getNextYearMonths = () => {
  const nextYear = moment().add(1, "year").format("YYYY");

  const arrayWithNextYear = monthsArr.map((element) => element + " " + nextYear);

  return arrayWithNextYear;
};

export const getProjectionMonthOptions = () => {
  return [...getCurrentYearMonthsWithTentative(), ...getNextYearMonths()];
};
export const convertMonthNameToKey = (monthYearString: string) => {
  const date = moment(monthYearString, "MMMM YYYY");

  const formattedString = date.format("MM-YYYY");

  return formattedString;
};
export const convertMonthkeyToName = (monthYearString: string) => {
  const date = moment(monthYearString, "MM-YYYY");

  const formattedString = date.format("MMMM YYYY");

  return formattedString;
};

export const downloadFileFromUrl = (uri: string) => {
  const link = document.createElement("a");
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isNotEqual = (val1: string | number, val2: string | number) => {
  if (isNullOrUndefiend(val2)) return false;
  return val1 !== val2;
};

export const areAllKeysNull = (obj: { [key: string]: unknown }) => {
  for (const key in obj) {
    if (obj[key] !== null) {
      return false;
    }
  }
  return true;
};

export const numWordsWrapper = (number: number) => {
  //check decimal point and remove
  const num = number?.toString()?.split(".");
  return num?.[0] ? numWords(Number(num?.[0] || 0)) : "-";
};

export const ConvertIRFDataForSummary = (irf?: IIRF, approvedIrf?: IIRF) => {
  const agreementDetails = irf?.agreementDetails;
  const approvedAgreementDetails = approvedIrf?.agreementDetails;

  const isAddressSame =
    isObjectNullOrUndefiendOrEmpty(irf?.shippingAddress) ||
    JSON.stringify(irf?.shippingAddress) === JSON.stringify(irf?.kyc?.address);

  const billingAddArr: IKeyValuePairForDetails[] = [
    {
      label: STRING_CONSTANTS.CLIENT_LEGAL_NAME,
      value: getValueOrDash(irf?.client?.legalEntityName),
      isChanged:
        approvedIrf && isNotEqual(irf?.client?.legalEntityName || "", approvedIrf?.client?.legalEntityName || ""),
    },
    {
      label: FORM_FIELDS.CLIENT_GST,
      value: getValueOrDash(irf?.kyc?.kycNumber),
      isChanged: approvedIrf && isNotEqual(irf?.kyc?.kycNumber || "", approvedIrf?.kyc?.kycNumber || ""),
    },
    {
      label: STRING_CONSTANTS.CLIENT_BILLING_ADD,
      value: getValueOrDash(irf?.kyc?.address?.address),
      isChanged: approvedIrf && isNotEqual(irf?.kyc?.address?.address || "", approvedIrf?.kyc?.address?.address || ""),
    },
    {
      label: FORM_FIELDS.PINCODE,
      value: getValueOrDash(irf?.kyc?.address?.pincode),
      isChanged: approvedIrf && isNotEqual(irf?.kyc?.address?.pincode || "", approvedIrf?.kyc?.address?.pincode || ""),
    },
    {
      label: FORM_FIELDS.CITY_LABEL,
      value: getValueOrDash(irf?.kyc?.address?.cityName),
      isChanged:
        approvedIrf && isNotEqual(irf?.kyc?.address?.cityName || "", approvedIrf?.kyc?.address?.cityName || ""),
    },
    {
      label: FORM_FIELDS.STATE,
      value: getValueOrDash(irf?.kyc?.address?.stateName),
      isChanged:
        approvedIrf && isNotEqual(irf?.kyc?.address?.stateName || "", approvedIrf?.kyc?.address?.stateName || ""),
    },
  ];
  const shippinAddArr: IKeyValuePairForDetails[] = isAddressSame
    ? []
    : [
        {
          label: STRING_CONSTANTS.CLIENT_SHIP_ADD,
          value: getValueOrDash(irf?.shippingAddress?.address),
          isChanged:
            approvedIrf && isNotEqual(irf?.shippingAddress?.address || "", approvedIrf?.shippingAddress?.address || ""),
        },
        {
          label: FORM_FIELDS.PINCODE,
          value: getValueOrDash(irf?.shippingAddress?.pincode),
          isChanged:
            approvedIrf && isNotEqual(irf?.shippingAddress?.pincode || "", approvedIrf?.shippingAddress?.pincode || ""),
        },
        {
          label: FORM_FIELDS.CITY_LABEL,
          value: getValueOrDash(irf?.shippingAddress?.cityName),
          isChanged:
            approvedIrf &&
            isNotEqual(irf?.shippingAddress?.cityName || "", approvedIrf?.shippingAddress?.cityName || ""),
        },
        {
          label: FORM_FIELDS.STATE,
          value: getValueOrDash(irf?.shippingAddress?.stateName),
          isChanged:
            approvedIrf &&
            isNotEqual(irf?.shippingAddress?.stateName || "", approvedIrf?.shippingAddress?.stateName || ""),
        },
      ];

  //loop for clien poc

  const contactsArr = [];

  for (let i = 0; i < (irf?.poc?.length || 0); i++) {
    const poc = irf?.poc?.[i];
    const approvedPoc = approvedIrf?.poc?.[i];
    contactsArr.push({
      label: `${FORM_FIELDS.CLIENT_POC_NAME} `,
      value: getValueOrDash(poc?.name),
      isChanged: approvedIrf && isNotEqual(poc?.name || "", approvedPoc?.name || ""),
    });
    contactsArr.push({
      label: `${FORM_FIELDS.CLIENT_POC_NO} `,
      value: getValueOrDash(poc?.phoneNumber?.number),
      isChanged: approvedIrf && isNotEqual(poc?.phoneNumber?.number || "", approvedPoc?.phoneNumber?.number || ""),
    });
    contactsArr.push({
      label: `${FORM_FIELDS.CLIENT_POC_EMAIL} `,
      value: getValueOrDash(poc?.email),
      isChanged: approvedIrf && isNotEqual(poc?.email || "", approvedPoc?.email || ""),
    });
  }

  contactsArr.push({
    label: FORM_FIELDS.PROPERTY_STATE,
    value: getValueOrDash(irf?.kyc?.address?.address),
    isChanged: approvedIrf && isNotEqual(irf?.kyc?.address?.address || "", approvedIrf?.kyc?.address?.address || ""),
  });
  contactsArr.push({
    label: FORM_FIELDS.CREDIT_PERIOD,
    value: `${getValueOrDash(agreementDetails?.credit?.period)} Days`,
    isChanged:
      approvedIrf && isNotEqual(agreementDetails?.credit?.period || "", approvedAgreementDetails?.credit?.period || ""),
  });
  contactsArr.push({
    label: FORM_FIELDS.CREDIT_RATINGS_LABEL,
    value: getValueOrDash(agreementDetails?.credit?.rating),
    isChanged:
      approvedIrf && isNotEqual(agreementDetails?.credit?.rating || "", approvedAgreementDetails?.credit?.rating || ""),
  });

  contactsArr.push({
    label: FORM_FIELDS.LESSOR_LL,
    value: getValueOrDash(irf?.secondParty),
    isChanged: approvedIrf && isNotEqual(irf?.secondParty || "", approvedIrf?.secondParty || ""),
  });
  contactsArr.push({
    label: FORM_FIELDS.ANAROCK_POC_NAME,
    value: getValueOrDash(irf?.anarockPOC?.agent?.name),
    isChanged:
      approvedIrf && isNotEqual(irf?.anarockPOC?.agent?.name || "", approvedIrf?.anarockPOC?.agent?.name || ""),
  });
  contactsArr.push({
    label: FORM_FIELDS.ANAROCK_POC_NO,
    value: irf?.anarockPOC?.agent?.phoneNumber?.number
      ? getValueOrDash(irf?.anarockPOC?.agent?.phoneNumber?.countryCode) +
        " " +
        getValueOrDash(irf?.anarockPOC?.agent?.phoneNumber?.number)
      : "-",
    isChanged:
      approvedIrf &&
      isNotEqual(
        irf?.anarockPOC?.agent?.phoneNumber?.number || "",
        approvedIrf?.anarockPOC?.agent?.phoneNumber?.number || ""
      ),
  });

  contactsArr.push({
    label: FORM_FIELDS.PROFIT_CENTER_LABEL,
    value: `${getValueOrDash(irf?.wipMetaData?.profitCenter)} `,
    isChanged:
      approvedIrf && isNotEqual(irf?.wipMetaData?.profitCenter || "", approvedIrf?.wipMetaData?.profitCenter || ""),
  });

  const propertyArr: IKeyValuePairForDetails[] = [];

  if (irf?.wipArea?.value) {
    propertyArr.push({
      label: FORM_FIELDS.PA_AS_PER_AGGREEMENT,
      value: getValueOrDash(irf?.wipArea?.value) + " " + STRING_CONSTANTS.SQ_FT,
      isChanged: approvedIrf && isNotEqual(irf?.wipArea?.value || "", approvedIrf?.wipArea?.value || ""),
    });
  }
  if (
    irf?.wipType === "LEASING" &&
    agreementDetails?.serviceFeeCalculationBasis1 !== FEE_CALCULATION_BASE_1["PURE_REV_SHARE"]
  ) {
    propertyArr.push({
      label: FORM_FIELDS.MIN_RENT_LABEL,
      value: convertToRupee(Number(agreementDetails?.rent?.value)),
      isChanged:
        approvedIrf && isNotEqual(agreementDetails?.rent?.value || "", approvedAgreementDetails?.rent?.value || ""),
    });
  }

  if (agreementDetails?.serviceFeeCalculationBasis1) {
    propertyArr.push({
      label: FORM_FIELDS.FEE_BASE_1,
      value: getValueOrDash(agreementDetails?.serviceFeeCalculationBasis1),
      isChanged:
        approvedIrf &&
        isNotEqual(
          agreementDetails?.serviceFeeCalculationBasis1 || "",
          approvedAgreementDetails?.serviceFeeCalculationBasis1 || ""
        ),
    });
  }
  if (agreementDetails?.serviceFeeCalculationBasis2) {
    propertyArr.push({
      label: FORM_FIELDS.FEE_BASE_2,
      value: getValueOrDash(agreementDetails?.serviceFeeCalculationBasis2),
      isChanged:
        approvedIrf &&
        isNotEqual(
          agreementDetails?.serviceFeeCalculationBasis2 || "",
          approvedAgreementDetails?.serviceFeeCalculationBasis2 || ""
        ),
    });
  }
  if (agreementDetails?.serviceFeeCalculationBasis1 === FEE_CALCULATION_BASE_1["FIXED_RENT"]) {
    propertyArr.push({
      label: FORM_FIELDS.NO_OF_DAYS,
      value: getValueOrDash(Number(agreementDetails?.noOfDays || 0)),
      isChanged: approvedIrf && isNotEqual(agreementDetails?.noOfDays || "", approvedAgreementDetails?.noOfDays || ""),
    });
    if (agreementDetails?.serviceFeeCalculationBasis2 === FIXED_RENT_BASE_OPTIONS["1"]) {
      propertyArr.push({
        label: FORM_FIELDS.MONTHLY_COMP,
        value: convertToRupee(Number(agreementDetails?.rent?.value)),
        isChanged:
          approvedIrf && isNotEqual(agreementDetails?.rent?.value || "", approvedAgreementDetails?.rent?.value || ""),
      });
    } else if (agreementDetails?.serviceFeeCalculationBasis2 === FIXED_RENT_BASE_OPTIONS["2"]) {
      propertyArr.push({
        label: FORM_FIELDS.MIN_RENT_LABEL,
        value: convertToRupee(Number(agreementDetails?.rent?.value)),
        isChanged:
          approvedIrf && isNotEqual(agreementDetails?.rent?.value || "", approvedAgreementDetails?.rent?.value || ""),
      });
    } else if (agreementDetails?.serviceFeeCalculationBasis2 === FIXED_RENT_BASE_OPTIONS["3"]) {
      propertyArr.push({
        label: FORM_FIELDS.RENT_LABEL,
        value: convertToRupee(Number(agreementDetails?.rent?.value)),
        isChanged:
          approvedIrf && isNotEqual(agreementDetails?.rent?.value || "", approvedAgreementDetails?.rent?.value || ""),
      });
    }
  } else if (agreementDetails?.serviceFeeCalculationBasis2 === PURE_REV_SHARING_OPTIONS[1]) {
    propertyArr.push({
      label: FORM_FIELDS.NO_OF_DAYS,
      value: getValueOrDash(Number(agreementDetails?.noOfDays || 0)),
      isChanged: approvedIrf && isNotEqual(agreementDetails?.noOfDays || "", approvedAgreementDetails?.noOfDays || ""),
    });
    propertyArr.push({
      label: FORM_FIELDS.SEC_DEP_AMT,
      value: convertToRupee(Number(agreementDetails?.securityDeposit)),
      isChanged:
        approvedIrf &&
        isNotEqual(agreementDetails?.securityDeposit || "", approvedAgreementDetails?.securityDeposit || ""),
    });
  } else if (agreementDetails?.serviceFeeCalculationBasis2 === STAGGERED_RENT_OPTIONS[1]) {
    propertyArr.push({
      label: FORM_FIELDS.NO_OF_DAYS,
      value: getValueOrDash(Number(agreementDetails?.noOfDays || 0)),
      isChanged: approvedIrf && isNotEqual(agreementDetails?.noOfDays || "", approvedAgreementDetails?.noOfDays || ""),
    });
    propertyArr.push({
      label: FORM_FIELDS.MONTHLY_AVG_RENT,
      value: convertToRupee(Number(agreementDetails?.rent?.value || 0)),
      isChanged:
        approvedIrf && isNotEqual(agreementDetails?.rent?.value || "", approvedAgreementDetails?.rent?.value || ""),
    });
  } else {
    propertyArr.push({
      label: FORM_FIELDS.LS_AMOUNT,
      value: convertToRupee(Number(agreementDetails?.feeAmount)),
      isChanged:
        approvedIrf && isNotEqual(agreementDetails?.feeAmount || "", approvedAgreementDetails?.feeAmount || ""),
    });
  }

  propertyArr.push({
    label: FORM_FIELDS.SERVICE_FEE,
    value: convertToRupee(Number(agreementDetails?.feeAmount)),
    isChanged: approvedIrf && isNotEqual(agreementDetails?.feeAmount || "", approvedAgreementDetails?.feeAmount || ""),
  });

  propertyArr.push({
    label: FORM_FIELDS.SERVICE_FEE,
    value: numWordsWrapper(Number(agreementDetails?.feeAmount || 0)),
    isChanged: approvedIrf && isNotEqual(agreementDetails?.feeAmount || "", approvedAgreementDetails?.feeAmount || ""),
  });
  propertyArr.push({
    label: FORM_FIELDS.INVOICING_AMT_WITH_TAX,
    value: convertToRupee(Number(agreementDetails?.invoiceAmount || 0)),
    isChanged:
      approvedIrf && isNotEqual(agreementDetails?.invoiceAmount || "", approvedAgreementDetails?.invoiceAmount || ""),
  });

  propertyArr.push({
    label: STRING_CONSTANTS.BILLING_MILESTONE,
    value: getValueOrDash(irf?.billingMilestone),
    isChanged: approvedIrf && isNotEqual(irf?.billingMilestone || "", approvedIrf?.billingMilestone || ""),
  });

  propertyArr.push({
    label: FORM_FIELDS.TYPE_OF_SERVICE,
    value: getValueOrDash(agreementDetails?.service?.type),
    isChanged:
      approvedIrf && isNotEqual(agreementDetails?.service?.type || "", approvedAgreementDetails?.service?.type || ""),
  });
  propertyArr.push({
    label: FORM_FIELDS.SRVC_DESCRIPTION_LABEL,
    value: getValueOrDash(agreementDetails?.service?.description),
    isChanged:
      approvedIrf &&
      isNotEqual(agreementDetails?.service?.description || "", approvedAgreementDetails?.service?.description || ""),
  });

  propertyArr.push({
    label: STRING_CONSTANTS.DEPARTMENT,
    value: STRING_CONSTANTS.RETAIL,
  });

  propertyArr.push({
    label: STRING_CONSTANTS.TOTAL_INVOICE_WITH_TAX,
    value: "**",
  });

  propertyArr.push({
    label: STRING_CONSTANTS.OUTSTANDING_AMT,
    value: "**",
  });

  propertyArr.push({
    label: STRING_CONSTANTS.COLLECTION_AMT,
    value: "**",
  });

  const commissionsArr: IKeyValuePairForDetails[] = [];
  const feeAmount = Number(agreementDetails?.feeAmount) || 0;
  const approvedFeeAmount = Number(approvedAgreementDetails?.feeAmount) || 0;

  const thirdPartyArr: IKeyValuePairForDetails[] = [];
  const tpShare = Number(agreementDetails?.thirdParty?.weightage) || 0;
  const approvedTpShare = Number(approvedAgreementDetails?.thirdParty?.weightage) || 0;

  const feeAmountAfterTPShare = feeAmount - (tpShare / 100) * feeAmount || 0;
  const approvedFeeAmountAfterTPShare = approvedFeeAmount - (approvedTpShare / 100) * approvedFeeAmount || 0;

  for (let i = 0; i < (irf?.allocation?.length || 0); i++) {
    const el = irf?.allocation?.[i];
    const commissionPercent = Number(el?.weightage) || 0;
    const approvedEl = approvedIrf?.allocation?.[i];
    // const approvedCommissionPercent = Number(approvedEl?.weightage) || 0;

    commissionsArr.push({
      label: `${STRING_CONSTANTS.PROFIT_CENTER}`,
      value: el?.profitCenter?.toString() || "-",
      isChanged: approvedIrf && isNotEqual(el?.profitCenter || "", approvedEl?.profitCenter || ""),
    });
    commissionsArr.push({
      label: `${STRING_CONSTANTS.REVENUE}`,
      value: `${convertToRupee((commissionPercent / 100) * feeAmountAfterTPShare)}(${commissionPercent}%)`,
      isChanged: approvedIrf && isNotEqual(feeAmountAfterTPShare, approvedFeeAmountAfterTPShare),
    });
    commissionsArr.push({
      label: ``,
      value: ``,
      isChanged: false,
    });
    commissionsArr.push({
      label: ``,
      value: ``,
      isChanged: false,
    });
  }

  if (agreementDetails?.thirdParty?.name) {
    thirdPartyArr.push({
      label: `${STRING_CONSTANTS.THIRD_PARTY}`,
      value: agreementDetails?.thirdParty?.name,
      isChanged:
        approvedIrf &&
        isNotEqual(agreementDetails?.thirdParty?.name || "", approvedAgreementDetails?.thirdParty?.name || ""),
    });
    thirdPartyArr.push({
      label: `${STRING_CONSTANTS.THIRD_PARTY_SHARE}`,
      value: `${convertToRupee((tpShare / 100) * feeAmount)}(${tpShare}%)`,
      isChanged: approvedIrf && isNotEqual(tpShare, approvedTpShare),
    });
  }

  return { billingAddArr, contactsArr, propertyArr, commissionsArr, thirdPartyArr, shippinAddArr, isAddressSame };
};

export const ConvertApprovedIRFDataForSummary = (irf?: IIRF, approvedIrf?: IIRF) => {
  const agreementDetails = irf?.agreementDetails;
  const approvedAgreementDetails = approvedIrf?.agreementDetails;

  const isApprovedAddressSame =
    isObjectNullOrUndefiendOrEmpty(approvedIrf?.shippingAddress) ||
    JSON.stringify(approvedIrf?.shippingAddress) === JSON.stringify(approvedIrf?.kyc?.address);

  const approvedBillingAddArr: IKeyValuePairForDetails[] = [
    {
      label: STRING_CONSTANTS.CLIENT_LEGAL_NAME,
      value: getValueOrDash(approvedIrf?.client?.legalEntityName),
      isChanged: isNotEqual(irf?.client?.legalEntityName || "", approvedIrf?.client?.legalEntityName || ""),
    },
    {
      label: FORM_FIELDS.CLIENT_GST,
      value: getValueOrDash(approvedIrf?.kyc?.kycNumber),
      isChanged: isNotEqual(irf?.kyc?.kycNumber || "", approvedIrf?.kyc?.kycNumber || ""),
    },
    {
      label: STRING_CONSTANTS.CLIENT_BILLING_ADD,
      value: getValueOrDash(approvedIrf?.kyc?.address?.address),
      isChanged: isNotEqual(irf?.kyc?.address?.address || "", approvedIrf?.kyc?.address?.address || ""),
    },
    {
      label: FORM_FIELDS.PINCODE,
      value: getValueOrDash(approvedIrf?.kyc?.address?.pincode),
      isChanged: isNotEqual(irf?.kyc?.address?.pincode || "", approvedIrf?.kyc?.address?.pincode || ""),
    },
    {
      label: FORM_FIELDS.CITY_LABEL,
      value: getValueOrDash(approvedIrf?.kyc?.address?.cityName),
      isChanged: isNotEqual(irf?.kyc?.address?.cityName || "", approvedIrf?.kyc?.address?.cityName || ""),
    },
    {
      label: FORM_FIELDS.STATE,
      value: getValueOrDash(approvedIrf?.kyc?.address?.stateName),
      isChanged: isNotEqual(irf?.kyc?.address?.stateName || "", approvedIrf?.kyc?.address?.stateName || ""),
    },
  ];
  const approvedShippinAddArr: IKeyValuePairForDetails[] = isApprovedAddressSame
    ? []
    : [
        {
          label: STRING_CONSTANTS.CLIENT_SHIP_ADD,
          value: getValueOrDash(approvedIrf?.shippingAddress?.address),
          isChanged: isNotEqual(irf?.shippingAddress?.address || "", approvedIrf?.shippingAddress?.address || ""),
        },
        {
          label: FORM_FIELDS.PINCODE,
          value: getValueOrDash(approvedIrf?.shippingAddress?.pincode),
          isChanged: isNotEqual(irf?.shippingAddress?.pincode || "", approvedIrf?.shippingAddress?.pincode || ""),
        },
        {
          label: FORM_FIELDS.CITY_LABEL,
          value: getValueOrDash(approvedIrf?.shippingAddress?.cityName),
          isChanged: isNotEqual(irf?.shippingAddress?.cityName || "", approvedIrf?.shippingAddress?.cityName || ""),
        },
        {
          label: FORM_FIELDS.STATE,
          value: getValueOrDash(approvedIrf?.shippingAddress?.stateName),
          isChanged: isNotEqual(irf?.shippingAddress?.stateName || "", approvedIrf?.shippingAddress?.stateName || ""),
        },
      ];

  //loop for clien poc

  const approvedContactsArr = [];

  for (let i = 0; i < (approvedIrf?.poc?.length || 0); i++) {
    const poc = irf?.poc?.[i];
    const approvedPoc = approvedIrf?.poc?.[i];
    approvedContactsArr.push({
      label: `${FORM_FIELDS.CLIENT_POC_NAME} `,
      value: getValueOrDash(approvedPoc?.name),
      isChanged: isNotEqual(poc?.name || "", approvedPoc?.name || ""),
    });
    approvedContactsArr.push({
      label: `${FORM_FIELDS.CLIENT_POC_NO} `,
      value: getValueOrDash(approvedPoc?.phoneNumber?.number),
      isChanged: isNotEqual(poc?.phoneNumber?.number || "", approvedPoc?.phoneNumber?.number || ""),
    });
    approvedContactsArr.push({
      label: `${FORM_FIELDS.CLIENT_POC_EMAIL} `,
      value: getValueOrDash(approvedPoc?.email),
      isChanged: isNotEqual(poc?.email || "", approvedPoc?.email || ""),
    });
  }

  approvedContactsArr.push({
    label: FORM_FIELDS.PROPERTY_STATE,
    value: getValueOrDash(approvedIrf?.kyc?.address?.address),
    isChanged: isNotEqual(irf?.kyc?.address?.address || "", approvedIrf?.kyc?.address?.address || ""),
  });
  approvedContactsArr.push({
    label: FORM_FIELDS.CREDIT_PERIOD,
    value: `${getValueOrDash(approvedAgreementDetails?.credit?.period)} Days`,
    isChanged: isNotEqual(agreementDetails?.credit?.period || "", approvedAgreementDetails?.credit?.period || ""),
  });
  approvedContactsArr.push({
    label: FORM_FIELDS.CREDIT_RATINGS_LABEL,
    value: getValueOrDash(approvedAgreementDetails?.credit?.rating),
    isChanged: isNotEqual(agreementDetails?.credit?.rating || "", approvedAgreementDetails?.credit?.rating || ""),
  });

  approvedContactsArr.push({
    label: FORM_FIELDS.LESSOR_LL,
    value: getValueOrDash(approvedIrf?.secondParty),
    isChanged: isNotEqual(irf?.secondParty || "", approvedIrf?.secondParty || ""),
  });
  approvedContactsArr.push({
    label: FORM_FIELDS.ANAROCK_POC_NAME,
    value: getValueOrDash(approvedIrf?.anarockPOC?.agent?.name),
    isChanged: isNotEqual(irf?.anarockPOC?.agent?.name || "", approvedIrf?.anarockPOC?.agent?.name || ""),
  });
  approvedContactsArr.push({
    label: FORM_FIELDS.ANAROCK_POC_NO,
    value: irf?.anarockPOC?.agent?.phoneNumber?.number
      ? getValueOrDash(approvedIrf?.anarockPOC?.agent?.phoneNumber?.countryCode) +
        " " +
        getValueOrDash(approvedIrf?.anarockPOC?.agent?.phoneNumber?.number)
      : "-",
    isChanged: isNotEqual(
      irf?.anarockPOC?.agent?.phoneNumber?.number || "",
      approvedIrf?.anarockPOC?.agent?.phoneNumber?.number || ""
    ),
  });

  approvedContactsArr.push({
    label: FORM_FIELDS.PROFIT_CENTER_LABEL,
    value: `${getValueOrDash(approvedIrf?.wipMetaData?.profitCenter)} `,
    isChanged: isNotEqual(irf?.wipMetaData?.profitCenter || "", approvedIrf?.wipMetaData?.profitCenter || ""),
  });

  const approvedPropertyArr: IKeyValuePairForDetails[] = [];

  if (approvedIrf?.wipArea?.value) {
    approvedPropertyArr.push({
      label: FORM_FIELDS.PA_AS_PER_AGGREEMENT,
      value: getValueOrDash(approvedIrf?.wipArea?.value) + " " + STRING_CONSTANTS.SQ_FT,
      isChanged: isNotEqual(irf?.wipArea?.value || "", approvedIrf?.wipArea?.value || ""),
    });
  }
  if (approvedIrf?.wipType === "LEASING") {
    approvedPropertyArr.push({
      label: FORM_FIELDS.MIN_RENT_LABEL,
      value: convertToRupee(Number(approvedAgreementDetails?.rent?.value)),
      isChanged: isNotEqual(agreementDetails?.rent?.value || "", approvedAgreementDetails?.rent?.value || ""),
    });
  }

  if (approvedAgreementDetails?.serviceFeeCalculationBasis1) {
    approvedPropertyArr.push({
      label: FORM_FIELDS.FEE_BASE_1,
      value: getValueOrDash(approvedAgreementDetails?.serviceFeeCalculationBasis1),
      isChanged: isNotEqual(
        agreementDetails?.serviceFeeCalculationBasis1 || "",
        approvedAgreementDetails?.serviceFeeCalculationBasis1 || ""
      ),
    });
  }
  if (approvedAgreementDetails?.serviceFeeCalculationBasis2) {
    approvedPropertyArr.push({
      label: FORM_FIELDS.FEE_BASE_2,
      value: getValueOrDash(approvedAgreementDetails?.serviceFeeCalculationBasis2),
      isChanged: isNotEqual(
        agreementDetails?.serviceFeeCalculationBasis2 || "",
        approvedAgreementDetails?.serviceFeeCalculationBasis2 || ""
      ),
    });
  }
  if (approvedAgreementDetails?.serviceFeeCalculationBasis1 === FEE_CALCULATION_BASE_1["FIXED_RENT"]) {
    approvedPropertyArr.push({
      label: FORM_FIELDS.NO_OF_DAYS,
      value: getValueOrDash(Number(approvedAgreementDetails?.noOfDays || 0)),
      isChanged: isNotEqual(agreementDetails?.noOfDays || "", approvedAgreementDetails?.noOfDays || ""),
    });
    if (approvedAgreementDetails?.serviceFeeCalculationBasis2 === FIXED_RENT_BASE_OPTIONS["1"]) {
      approvedPropertyArr.push({
        label: FORM_FIELDS.MONTHLY_COMP,
        value: convertToRupee(Number(approvedAgreementDetails?.rent?.value)),
        isChanged: isNotEqual(agreementDetails?.rent?.value || "", approvedAgreementDetails?.rent?.value || ""),
      });
    } else if (approvedAgreementDetails?.serviceFeeCalculationBasis2 === FIXED_RENT_BASE_OPTIONS["2"]) {
      approvedPropertyArr.push({
        label: FORM_FIELDS.MIN_RENT_LABEL,
        value: convertToRupee(Number(approvedAgreementDetails?.rent?.value)),
        isChanged: isNotEqual(agreementDetails?.rent?.value || "", approvedAgreementDetails?.rent?.value || ""),
      });
    } else if (approvedAgreementDetails?.serviceFeeCalculationBasis2 === FIXED_RENT_BASE_OPTIONS["3"]) {
      approvedPropertyArr.push({
        label: FORM_FIELDS.RENT_LABEL,
        value: convertToRupee(Number(approvedAgreementDetails?.rent?.value)),
        isChanged: isNotEqual(agreementDetails?.rent?.value || "", approvedAgreementDetails?.rent?.value || ""),
      });
    }
  } else if (approvedAgreementDetails?.serviceFeeCalculationBasis2 === PURE_REV_SHARING_OPTIONS[1]) {
    approvedPropertyArr.push({
      label: FORM_FIELDS.NO_OF_DAYS,
      value: getValueOrDash(Number(approvedAgreementDetails?.noOfDays || 0)),
      isChanged: isNotEqual(agreementDetails?.noOfDays || "", approvedAgreementDetails?.noOfDays || ""),
    });
    approvedPropertyArr.push({
      label: FORM_FIELDS.SEC_DEP_AMT,
      value: convertToRupee(Number(approvedAgreementDetails?.securityDeposit)),
      isChanged: isNotEqual(agreementDetails?.securityDeposit || "", approvedAgreementDetails?.securityDeposit || ""),
    });
  } else if (approvedAgreementDetails?.serviceFeeCalculationBasis2 === STAGGERED_RENT_OPTIONS[1]) {
    approvedPropertyArr.push({
      label: FORM_FIELDS.NO_OF_DAYS,
      value: getValueOrDash(Number(approvedAgreementDetails?.noOfDays || 0)),
      isChanged: isNotEqual(agreementDetails?.noOfDays || "", approvedAgreementDetails?.noOfDays || ""),
    });
    approvedPropertyArr.push({
      label: FORM_FIELDS.MONTHLY_AVG_RENT,
      value: convertToRupee(Number(approvedAgreementDetails?.rent?.value || 0)),
      isChanged: isNotEqual(agreementDetails?.rent?.value || "", approvedAgreementDetails?.rent?.value || ""),
    });
  } else {
    approvedPropertyArr.push({
      label: FORM_FIELDS.LS_AMOUNT,
      value: convertToRupee(Number(approvedAgreementDetails?.feeAmount)),
      isChanged: isNotEqual(agreementDetails?.feeAmount || "", approvedAgreementDetails?.feeAmount || ""),
    });
  }

  approvedPropertyArr.push({
    label: FORM_FIELDS.SERVICE_FEE,
    value: convertToRupee(Number(approvedAgreementDetails?.feeAmount)),
    isChanged: isNotEqual(agreementDetails?.feeAmount || "", approvedAgreementDetails?.feeAmount || ""),
  });

  approvedPropertyArr.push({
    label: FORM_FIELDS.SERVICE_FEE,
    value: numWordsWrapper(Number(approvedAgreementDetails?.feeAmount || 0)),
    isChanged: isNotEqual(agreementDetails?.feeAmount || "", approvedAgreementDetails?.feeAmount || ""),
  });
  approvedPropertyArr.push({
    label: FORM_FIELDS.INVOICING_AMT_WITH_TAX,
    value: convertToRupee(Number(approvedAgreementDetails?.invoiceAmount || 0)),
    isChanged: isNotEqual(agreementDetails?.invoiceAmount || "", approvedAgreementDetails?.invoiceAmount || ""),
  });

  approvedPropertyArr.push({
    label: STRING_CONSTANTS.BILLING_MILESTONE,
    value: getValueOrDash(approvedIrf?.billingMilestone),
    isChanged: isNotEqual(irf?.billingMilestone || "", approvedIrf?.billingMilestone || ""),
  });

  approvedPropertyArr.push({
    label: FORM_FIELDS.TYPE_OF_SERVICE,
    value: getValueOrDash(approvedAgreementDetails?.service?.type),
    isChanged: isNotEqual(agreementDetails?.service?.type || "", approvedAgreementDetails?.service?.type || ""),
  });
  approvedPropertyArr.push({
    label: FORM_FIELDS.SRVC_DESCRIPTION_LABEL,
    value: getValueOrDash(approvedAgreementDetails?.service?.description),
    isChanged: isNotEqual(
      agreementDetails?.service?.description || "",
      approvedAgreementDetails?.service?.description || ""
    ),
  });

  approvedPropertyArr.push({
    label: STRING_CONSTANTS.DEPARTMENT,
    value: STRING_CONSTANTS.RETAIL,
  });

  approvedPropertyArr.push({
    label: STRING_CONSTANTS.TOTAL_INVOICE_WITH_TAX,
    value: "**",
  });

  approvedPropertyArr.push({
    label: STRING_CONSTANTS.OUTSTANDING_AMT,
    value: "**",
  });

  approvedPropertyArr.push({
    label: STRING_CONSTANTS.COLLECTION_AMT,
    value: "**",
  });

  const approvedCommissionsArr: IKeyValuePairForDetails[] = [];
  const feeAmount = Number(agreementDetails?.feeAmount) || 0;
  const approvedFeeAmount = Number(approvedAgreementDetails?.feeAmount) || 0;

  const approvedThirdPartyArr: IKeyValuePairForDetails[] = [];
  const tpShare = Number(agreementDetails?.thirdParty?.weightage) || 0;
  const approvedTpShare = Number(approvedAgreementDetails?.thirdParty?.weightage) || 0;

  const feeAmountAfterTPShare = feeAmount - (tpShare / 100) * feeAmount || 0;
  const approvedFeeAmountAfterTPShare = approvedFeeAmount - (approvedTpShare / 100) * approvedFeeAmount || 0;

  for (let i = 0; i < (approvedIrf?.allocation?.length || 0); i++) {
    const el = irf?.allocation?.[i];
    // const commissionPercent = Number(el?.weightage) || 0;
    const approvedEl = approvedIrf?.allocation?.[i];
    const approvedCommissionPercent = Number(approvedEl?.weightage) || 0;

    approvedCommissionsArr.push({
      label: `${STRING_CONSTANTS.PROFIT_CENTER}`,
      value: approvedEl?.profitCenter?.toString() || "-",
      isChanged: isNotEqual(el?.profitCenter || "", approvedEl?.profitCenter || ""),
    });
    approvedCommissionsArr.push({
      label: `${STRING_CONSTANTS.REVENUE}`,
      value: `${convertToRupee(
        (approvedCommissionPercent / 100) * approvedFeeAmountAfterTPShare
      )}(${approvedCommissionPercent}%)`,
      isChanged: isNotEqual(feeAmountAfterTPShare, approvedFeeAmountAfterTPShare),
    });
    approvedCommissionsArr.push({
      label: ``,
      value: ``,
      isChanged: false,
    });
    approvedCommissionsArr.push({
      label: ``,
      value: ``,
      isChanged: false,
    });
  }

  if (approvedAgreementDetails?.thirdParty?.name) {
    approvedThirdPartyArr.push({
      label: `${STRING_CONSTANTS.THIRD_PARTY}`,
      value: approvedAgreementDetails?.thirdParty?.name,
      isChanged: isNotEqual(agreementDetails?.thirdParty?.name || "", approvedAgreementDetails?.thirdParty?.name || ""),
    });
    approvedThirdPartyArr.push({
      label: `${STRING_CONSTANTS.THIRD_PARTY_SHARE}`,
      value: `${convertToRupee((approvedTpShare / 100) * approvedFeeAmount)}(${approvedTpShare}%)`,
      isChanged: isNotEqual(tpShare, approvedTpShare),
    });
  }

  return {
    approvedBillingAddArr,
    approvedContactsArr,
    approvedPropertyArr,
    approvedCommissionsArr,
    approvedThirdPartyArr,
    approvedShippinAddArr,
    isApprovedAddressSame,
  };
};

export const capitalizeFirstLetter = (string?: string) => {
  if (!string) {
    return "-";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getFileExtension = (name: string) => {
  try {
    return name?.split(".")?.pop() || "";
  } catch {
    return "";
  }
};

export const disabledFutureDate = (current: Dayjs) => {
  return current && current > dayjs(new Date());
};

export const getCurretAndPreviousMonthOptions = (isCutOffEnabled: boolean) => {
  const currentMonth = moment();
  const prevMonth = moment().subtract(1, "months");
  return [
    { label: currentMonth.format("MMMM YYYY"), value: currentMonth.format("MM-YYYY") },
    ...(!isCutOffEnabled ? [{ label: prevMonth.format("MMMM YYYY"), value: prevMonth.format("MM-YYYY") }] : []),
  ];
};

export const getCityAndStateNameFromPincodeData = (pincode?: any) => {
  const addressComponents = pincode?.results[0]?.address_components;
  let state, city;

  addressComponents?.forEach((component: any) => {
    if (component.types.includes("administrative_area_level_1")) {
      state = component.long_name;
    }
    if (component.types.includes("locality") || component.types.includes("administrative_area_level_3")) {
      city = component.long_name;
    }
  });
  return { city, state };
};

export const findEnumKeyByValue = (enumObj: IEnum, value: string) => {
  return Object.keys(enumObj).find((key) => enumObj[key] === value);
};
