import { ArButton, ArModal, ButtonTypes } from "@anarock/ar-common-react";
import { classNames } from "@anarock/ar-common-react/dist/utils/classNames";
import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  useLazyGetSignedUrlForEffectiveCTCExcelQuery,
  useLazyGetSignedUrlForIncentivePayoutExcelQuery,
  useLazyGetSignedUrlForTDSConfirmationExcelQuery,
  useValidateEffectiveCTCSheetMutation,
  useValidateIncentivePayoutSheetMutation,
  useValidateTDSConfirmationSheetMutation,
} from "app/services/incentives";
import { useGetJobStatusQuery } from "app/services/optionListApi";
import {
  useLazyGetSignedUrlForCollectionExcelQuery,
  useLazyGetSignedUrlForExcelQuery,
  useValidateCollectionSheetMutation,
  useValidateSheetMutation,
} from "app/services/previewSheet";
import { useUploadDocumentToUrlMutation } from "app/services/wip";
import { isNullOrUndefiend, openURLInNewTab } from "app/utils/helperFunctions";
import { useQueryApiError } from "app/utils/useQueryApiError";
import { useToastService } from "components/toastService";
import { SAMPLE_COLLECTION_SHEET_URL, SAMPLE_INVOICE_SHEET_URL, SAMPLE_REVENUE_SHEET_URL } from "constants/index";
import { BROCHURE_STATUS } from "constants/ListingEnums";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IWipImportSheetModalProps, SheetTypeEnum } from "./index.interface";
import styles from "./index.module.scss";
import { UploadModal } from "./partials";

const ImportSheetModal = ({
  type,
  onCancel,
  closeModal: closeModalProp,
  replace,
  ...props
}: IWipImportSheetModalProps) => {
  const sheetName =
    type === SheetTypeEnum.INVOICE
      ? STRING_CONSTANTS.INVOICE
      : type === SheetTypeEnum.REVENUE
      ? STRING_CONSTANTS.REVENUE
      : type === SheetTypeEnum.COLLECTION
      ? STRING_CONSTANTS.COLLECTION
      : type === SheetTypeEnum.PAYOUT
      ? STRING_CONSTANTS.INCENTIVE_PAYOUT
      : type === SheetTypeEnum.TDS_CONFIRMATION
      ? STRING_CONSTANTS.TDS_CONFIRMATION
      : type === SheetTypeEnum.EFFECTIVE_CTC
      ? STRING_CONSTANTS.EFFECTIVE_CTC
      : "";

  const [sheetId, setSheetId] = useState<string | undefined>();
  const [fileName, setFileName] = useState<string | undefined>();
  const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false);

  const [jobId, setJobId] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  const [confirmModal, setConfirmModal] = useState(false);

  const { errorToast, inProgressToast } = useToastService();

  const [uploadDocument] = useUploadDocumentToUrlMutation();

  const [validateSheet, { isLoading, data }] = useValidateSheetMutation();

  const {
    data: jobData,
    isError,
    isFetching,
    isLoading: jobLoading,
  } = useGetJobStatusQuery(jobId || "", {
    refetchOnMountOrArgChange: true,
    pollingInterval: 3000,
    skip: isNullOrUndefiend(jobId),
  });
  useQueryApiError(isError, isLoading, isFetching, jobData);

  const [validateCollectionSheet, { isLoading: collectionLoading, data: collectionData }] =
    useValidateCollectionSheetMutation();

  const [validateIncentivePayoutSheet, { isLoading: incentivePayoutLoading, data: incentivePayoutData }] =
    useValidateIncentivePayoutSheetMutation();

  const [validateTDSConfirmationSheet, { isLoading: tdsConfirmationLoading, data: tdsConfirmationData }] =
    useValidateTDSConfirmationSheetMutation();

  const [validateEffectiveCTCSheet, { isLoading: effectiveCTCLoading, data: effectiveCTCData }] =
    useValidateEffectiveCTCSheetMutation();

  const [getSignedUrl] = useLazyGetSignedUrlForExcelQuery();

  const [getSignedUrlForCollection] = useLazyGetSignedUrlForCollectionExcelQuery();

  const [getSignedUrlForIncentivePayout] = useLazyGetSignedUrlForIncentivePayoutExcelQuery();

  const [getSignedUrlForTDSConfirmationPayout] = useLazyGetSignedUrlForTDSConfirmationExcelQuery();

  const [getSignedUrlForEffectiveCTC] = useLazyGetSignedUrlForEffectiveCTCExcelQuery();

  const handleUpload: (props: any) => void = async ({ file, onSuccess, onError }) => {
    if (isNullOrUndefiend(file) || !type) {
      onError();
      return;
    } else {
      let signedUrlResponse;

      try {
        //todo get url or payout sheet
        const data =
          type === SheetTypeEnum.COLLECTION
            ? await getSignedUrlForCollection().unwrap()
            : type === SheetTypeEnum.PAYOUT
            ? await getSignedUrlForIncentivePayout().unwrap()
            : type === SheetTypeEnum.TDS_CONFIRMATION
            ? await getSignedUrlForTDSConfirmationPayout().unwrap()
            : type === SheetTypeEnum.EFFECTIVE_CTC
            ? await getSignedUrlForEffectiveCTC().unwrap()
            : await getSignedUrl(type).unwrap();
        signedUrlResponse = data;
      } catch {
        errorToast(TOAST_MESSAGES.FAILED_TO_GENERATE_URL);
      }
      if (signedUrlResponse?.success) {
        const uploadUrlObject = signedUrlResponse?.data?.uploadSheetURL;
        const id = signedUrlResponse?.data?.sheetId;
        const formData = new FormData();
        for (const key in uploadUrlObject?.fields) {
          formData.append(key, uploadUrlObject?.fields[key] || "");
        }
        formData.append("file", file);
        uploadDocument({
          url: uploadUrlObject?.url || "",
          body: formData,
        })
          .unwrap()
          .then(() => {
            onSuccess();
            setSheetId(id);
            setFileName(file?.name || sheetName);
          })
          .catch((err) => {
            errorToast(err.message);
            onError();
          });
      } else {
        errorToast(signedUrlResponse?.reason || TOAST_MESSAGES.FAILED_TO_GENERATE_URL);
        onError();
      }
    }
  };

  const confirmSubmission = async () => {
    if (!sheetId || !type) {
      errorToast(TOAST_MESSAGES.SHEET_ID_NOT_AVLBL);
      return;
    }
    let response;
    try {
      //todo same fo rpyaout sheet
      response =
        type === SheetTypeEnum.COLLECTION
          ? await validateCollectionSheet(sheetId).unwrap()
          : type === SheetTypeEnum.PAYOUT
          ? await validateIncentivePayoutSheet(sheetId).unwrap()
          : type === SheetTypeEnum.TDS_CONFIRMATION
          ? await validateTDSConfirmationSheet(sheetId).unwrap()
          : type === SheetTypeEnum.EFFECTIVE_CTC
          ? await validateEffectiveCTCSheet(sheetId).unwrap()
          : await validateSheet({ type, sheetId }).unwrap();
    } catch (e) {
      errorToast(response?.reason || TOAST_MESSAGES.UNABLE_TO_SYNC_SHEET);
    }

    if (response?.success && response?.data?.jobId) {
      setJobId(response?.data?.jobId);
      inProgressToast(
        `Importing ${
          type === SheetTypeEnum.TDS_CONFIRMATION
            ? STRING_CONSTANTS.TDS_CONFIRMATION
            : type === SheetTypeEnum.EFFECTIVE_CTC
            ? STRING_CONSTANTS.EFFECTIVE_CTC
            : type
        } sheet in progress`
      );
    } else {
      errorToast(response?.reason || TOAST_MESSAGES.UNABLE_TO_SYNC_SHEET);
      setShowErrorScreen(true);
    }
  };

  const Footer = () => {
    if (showErrorScreen) {
      return (
        <div className={styles.importSheetFooter}>
          <ArButton type={ButtonTypes.Outlined} className={styles.footerBtn} onClick={closeParentModal}>
            {STRING_CONSTANTS.CANCEL}
          </ArButton>
          <ArButton className={styles.footerBtnLg} onClick={handleUploadnSync}>
            {STRING_CONSTANTS.RE_UPLOAD} {sheetName} {STRING_CONSTANTS.SHEET}
          </ArButton>
        </div>
      );
    }

    if (sheetId) {
      return (
        <div className={styles.importSheetFooter}>
          <ArButton type={ButtonTypes.Outlined} className={styles.footerBtn} onClick={closeParentModal}>
            {STRING_CONSTANTS.CANCEL}
          </ArButton>
          <ArButton
            className={styles.footerBtn}
            onClick={confirmSubmission}
            loading={
              isLoading ||
              collectionLoading ||
              jobLoading ||
              incentivePayoutLoading ||
              tdsConfirmationLoading ||
              effectiveCTCLoading
            }
          >
            {STRING_CONSTANTS.LETS_START_SYNC}
          </ArButton>
        </div>
      );
    }
    return <div className={styles.importSheetFooter}></div>;
  };

  const closeUploadModal = () => {
    closeModalProp();
    setConfirmModal(false);
    setSheetId(undefined);
    setFileName(undefined);
    setShowErrorScreen(false);
  };

  const closeParentModal = () => {
    if (
      data?.reason ||
      collectionData?.reason ||
      incentivePayoutData?.reason ||
      tdsConfirmationData?.reason ||
      effectiveCTCData?.reason
    ) {
      closeUploadModal();
    } else if (sheetId) {
      setConfirmModal(true);
    } else {
      closeUploadModal();
    }
  };

  const handleUploadnSync = () => {
    setSheetId(undefined);
    setFileName(undefined);
    setShowErrorScreen(false);
  };

  const handleSampleClick = () => {
    const sheeturl =
      type === SheetTypeEnum.INVOICE
        ? SAMPLE_INVOICE_SHEET_URL
        : type === SheetTypeEnum?.REVENUE
        ? SAMPLE_REVENUE_SHEET_URL
        : SAMPLE_COLLECTION_SHEET_URL;

    openURLInNewTab(sheeturl);
  };

  const redirectUserToPreview = useCallback(() => {
    type === SheetTypeEnum.COLLECTION
      ? navigate(`${APP_ROUTES.COLLECTIONS}${APP_ROUTES.PREVIEW}/${sheetId}`, {
          replace,
        })
      : type === SheetTypeEnum.PAYOUT
      ? navigate(`/${APP_ROUTES.INCENTIVES}${APP_ROUTES.PREVIEW}/${sheetId}`, { replace })
      : type === SheetTypeEnum.TDS_CONFIRMATION
      ? navigate(`/${APP_ROUTES.INCENTIVES}/${APP_ROUTES.TDS}${APP_ROUTES.PREVIEW}/${sheetId}`, { replace })
      : type === SheetTypeEnum.EFFECTIVE_CTC
      ? navigate(`/${APP_ROUTES.INCENTIVES}/${APP_ROUTES.CTC}${APP_ROUTES.PREVIEW}/${sheetId}`, { replace })
      : navigate(`${APP_ROUTES.WIP}${APP_ROUTES.PREVIEW}/${type}/${sheetId}`, {
          replace,
        });
  }, [type, sheetId, navigate, replace]);

  useEffect(() => {
    if (isNullOrUndefiend(jobId) || jobLoading) {
      return;
    }
    if (isError || !jobData?.success || jobData?.data?.jobDoc?.status === BROCHURE_STATUS.FAILED) {
      errorToast(jobData?.reason || STRING_CONSTANTS.ERROR_DOWNLOADING);
      setJobId(undefined);
    } else if (jobData?.success && jobData?.data?.jobDoc?.status === BROCHURE_STATUS.PROCESSED) {
      closeUploadModal();
      setJobId(undefined);
      redirectUserToPreview();
    }
  }, [isError, jobData, jobId, jobLoading, sheetId, type, redirectUserToPreview, replace]);

  return (
    <>
      <ArModal
        footer={<Footer />}
        title={STRING_CONSTANTS.IMPORT_N_SYNC + sheetName + STRING_CONSTANTS.UPDATE_SHEET}
        width={900}
        showBorderForHeader
        onCancel={closeParentModal}
        destroyOnClose
        {...props}
      >
        {!showErrorScreen ? (
          <div className={styles.importSheetModal}>
            <DownloadOutlined className={styles.uploadIcon} />
            <h5 className="hero-text-medium">
              {`${STRING_CONSTANTS.WHAT_HAPPENS_WHEN_WE} ${STRING_CONSTANTS.IMPORT_N_SYNC}${sheetName}${STRING_CONSTANTS.UPDATE_SHEET} ?`}
            </h5>
            <p className="sub-hero-text-regular pb-16 pt-8">
              {type === SheetTypeEnum.REVENUE ? (
                <>
                  {STRING_CONSTANTS.REVENUE_1}
                  <br></br>
                  {STRING_CONSTANTS.REVENUE_2}
                </>
              ) : type === SheetTypeEnum.INVOICE ? (
                <>
                  {STRING_CONSTANTS.INVOICE_1}
                  <br></br>
                  {STRING_CONSTANTS.INVOICE_2}
                  <br></br>
                  {STRING_CONSTANTS.INVOICE_3}
                </>
              ) : type === SheetTypeEnum.COLLECTION ? (
                <>
                  {STRING_CONSTANTS.COLLECTION_1}
                  <br></br>
                  {STRING_CONSTANTS.COLLECTION_2}
                </>
              ) : type === SheetTypeEnum.PAYOUT ? (
                <>
                  {STRING_CONSTANTS.PAYOUT_1}
                  <br></br>
                  {STRING_CONSTANTS.PAYOUT_2}
                  <br></br>
                  {STRING_CONSTANTS.PAYOUT_3}
                </>
              ) : type === SheetTypeEnum.TDS_CONFIRMATION ? (
                <>
                  {STRING_CONSTANTS.TDS_CONFIRMATION_1}
                  <br></br>
                  {STRING_CONSTANTS.TDS_CONFIRMATION_2}
                  <br></br>
                  {STRING_CONSTANTS.TDS_CONFIRMATION_3}
                  <br></br>
                  {STRING_CONSTANTS.TDS_CONFIRMATION_4}
                </>
              ) : type === SheetTypeEnum.EFFECTIVE_CTC ? (
                <>
                  {STRING_CONSTANTS.EFFECTIVE_CTC_1}
                  <br></br>
                  {STRING_CONSTANTS.EFFECTIVE_CTC_2}
                  <br></br>
                  {STRING_CONSTANTS.EFFECTIVE_CTC_3}
                  <br></br>
                  {STRING_CONSTANTS.EFFECTIVE_CTC_4}
                </>
              ) : null}
            </p>

            <UploadModal
              setSheetId={setSheetId}
              sheetId={sheetId}
              sheetName={fileName || sheetName}
              handleUpload={handleUpload}
            />
            {isNullOrUndefiend(sheetId) &&
              type !== SheetTypeEnum.PAYOUT &&
              type !== SheetTypeEnum.TDS_CONFIRMATION &&
              type !== SheetTypeEnum.EFFECTIVE_CTC && (
                <ArButton
                  type={ButtonTypes.Link}
                  onClick={handleSampleClick}
                >{`${sheetName} ${STRING_CONSTANTS.SHEET} ${STRING_CONSTANTS.SAMPLE}`}</ArButton>
              )}
          </div>
        ) : (
          <div {...classNames(styles.importSheetModal, styles.errorContainer)}>
            <h5 className="sub-hero-text pt-16 pb-16">{STRING_CONSTANTS.IMPORTED_FILE}</h5>

            <UploadModal
              setSheetId={setSheetId}
              sheetId={sheetId}
              sheetName={fileName || `${sheetName} ${STRING_CONSTANTS.SHEET}`}
              handleUpload={handleUpload}
              showDeleteIcon={false}
            />

            <p {...classNames("sub-hero-text", styles.failedText)}>
              <ExclamationCircleOutlined /> {STRING_CONSTANTS.SYNC_FAILED}
            </p>
            <p className="pb-4 pt-4 sub-hero-text">{STRING_CONSTANTS.POSSIBLE_REASON}</p>
            <p>
              {data?.reason ||
                collectionData?.reason ||
                incentivePayoutData?.reason ||
                tdsConfirmationData?.reason ||
                effectiveCTCData?.reason}
            </p>
          </div>
        )}
      </ArModal>
      <ArModal
        title={STRING_CONSTANTS.LEAVE_WITHOUT_SYNCING}
        submitText={STRING_CONSTANTS.YES_LEAVE}
        cancelText={STRING_CONSTANTS.NO_WAIT}
        open={confirmModal}
        onCancel={() => setConfirmModal(false)}
        onSubmit={closeUploadModal}
        destroyOnClose
      >
        {STRING_CONSTANTS.CONFIRM_LEAVE_IMPORT_1} {sheetName} {STRING_CONSTANTS.CONFIRM_LEAVE_IMPORT_2}
      </ArModal>
    </>
  );
};

export default ImportSheetModal;
