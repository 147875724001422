import { INCENTIVE_URLS, INVOICES_URLS, PREVIEW_URLS } from "constants/ApiUrls";
import { ALLOCATION_STATUS } from "constants/index";

import { IAnalyticsTable, IFilterListResponse } from "./analytics";
import { api, GenericResponse } from "./api";
import { IFiltersRequest, IIRFRequestBody, ISortBody } from "./invoices";
import { IPaginationParam } from "./notificationsApi";
import { IBrochureResponse } from "./optionListApi";
import { IGetPreviewResponse, IUploadImageUrlResponse, IValidateSheetResponse } from "./previewSheet";

export interface IIRFUserAllocation {
  userId: string;
  userName: string;
  weightage: number;
}
export interface IIRFAllocation {
  clientName: string;
  irfId: string;
  allocatedRevenue: number;
  allocatedRevenuePerc: number;
  netRevenue: number;
  allocationStatus: string;
  irfUserAllocation: {
    allocationStatus: ALLOCATION_STATUS;
    allocation: IIRFUserAllocation[];
    pooledRevenuePercentage: number;
  };
  pooledRevenuePercentage: number;
}
export interface IAllocationRequest {
  profitCenter: string;
  pooledRevenuePercentage: number;
  netRevenue?: number;
  allocation: IIRFUserAllocation[];
  allocationStatus: ALLOCATION_STATUS;
  irfProjectId: string;
}
export interface IIncentive {
  fyKey: string;
  profitCenter: string | string[];
  agentId: string | string[];
  accruedIncentive?: string;
  agentCollection?: string;
  agentDocketedCollection?: string;
  agentDocketedRevenue?: string;
  payableIncentive?: string | number;
  paidIncentive: string | number;
  agentRevenue?: string;
  agentName?: string;
  employeeId?: string;
  teamRevenue?: string;
  teamCollection?: string;
  teamDocketedCollection?: string;
  teamDocketedRevenue?: string;
  teamTier?: string;
  accrualEligibility?: string;
  payableEligibility?: string;
  employmentDuration?: string;
  employeeStatus?: string;
  teamProfit?: string;
  teamPLMargin?: string;
  teamThreshold?: string;
  agentThreshold?: string;
  teamEffectiveCtc?: string;
  balancePayable?: string | number;
  balanceIncentive?: string | number;
}

export interface IIncentiveKPITiles {
  accruedIncentive: number;
  agentCollection: number;
  agentDocketedCollection: number;
  agentDocketedRevenue: number;
  agentRevenue: number;
  fyKey: string;
  paidIncentive: number;
  payableIncentive: number;
  balanceIncentive: number;
  pendingTDS: number;
  lastUpdated: string;
}

export interface IIncentiveAnalyticsData {
  feeAmount: number;
  outstandingFee: number;
  collectedFeeWithin60Days: number;
  collectedFee60to120Days: number;
  collectedFeeAfter120Days: number;
  "outstandingFee-100-10": number;
  "outstandingFee-100-30": number;
  "outstandingFee-100-60": number;
  "outstandingFee-95-10": number;
  "outstandingFee-95-30": number;
  "outstandingFee-95-60": number;
  "outstandingFee-90": number;
}

export interface IWipIncentiveAnalyticsData {
  shopBrokerageAmount: number;
  brandBrokerageAmount: number;
  shopOkayAmount: number;
  brandOkayAmount: number;
  billed: number;
  pending: number;
  brokerage: number;
}

export type IIncentiveListResponse = GenericResponse<{
  incentiveList: IIncentive[];
  summaryRow: IIncentive[];
  paginationParams: IPaginationParam;
}>;

export type IIncentiveKPITilesResponse = GenericResponse<{
  incentiveData: IIncentiveKPITiles;
}>;

export type IIncentiveAnalyticsResponse = GenericResponse<{
  incentiveAnalyticsData: IIncentiveAnalyticsData;
  wipIncentiveAnalyticsData: IWipIncentiveAnalyticsData;
}>;

export const incentivesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    incentiveListingFilters: builder.query<IFilterListResponse, IFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.INCENTIVE}${INCENTIVE_URLS.FILTER}`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    incentiveKPITiles: builder.query<IIncentiveKPITilesResponse, IFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.INCENTIVE}`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    getIncentiveList: builder.query<IIncentiveListResponse, IIRFRequestBody>({
      query: ({ selectedFilters, params, sort }) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.INCENTIVE}${INCENTIVE_URLS.LIST}`,
        method: "POST",
        body: { selectedFilters, sort },
        params,
      }),
      providesTags: ["IncentiveList"],
    }),
    exportIncentiveReportExcel: builder.mutation<IBrochureResponse, IFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.INCENTIVE}${INCENTIVE_URLS.LIST}${INCENTIVE_URLS.EXPORT}`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    fetchCollectionHistoryTable: builder.query<
      GenericResponse<IAnalyticsTable>,
      { agentId: string[]; fy: string; profitCenter: string[]; sort?: ISortBody }
    >({
      query: ({ agentId, fy, profitCenter, sort }) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.COLLECTION}${INCENTIVE_URLS.LIST}`,
        method: "POST",
        body: {
          selectedValues: { BRAND_MANAGER: agentId, DURATION_IN_FY: fy, PROFIT_CENTER: profitCenter },
          sort,
        },
      }),
    }),
    fetchRevenueHistoryTable: builder.query<
      GenericResponse<IAnalyticsTable>,
      { agentId: string[]; fy: string; profitCenter: string[]; sort?: ISortBody }
    >({
      query: ({ agentId, fy, profitCenter, sort }) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.REVENUE}${INCENTIVE_URLS.LIST}`,
        method: "POST",
        body: {
          selectedValues: { BRAND_MANAGER: agentId, DURATION_IN_FY: fy, PROFIT_CENTER: profitCenter },
          sort,
        },
      }),
    }),
    fetchPaymentHistoryTable: builder.query<
      GenericResponse<IAnalyticsTable>,
      { agentId: string[]; fy: string; profitCenter: string[]; sort?: ISortBody }
    >({
      query: ({ agentId, fy, profitCenter, sort }) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.PAYOUT}${INCENTIVE_URLS.HISTORY}${INCENTIVE_URLS.LIST}`,
        method: "POST",
        body: {
          selectedValues: { BRAND_MANAGER: agentId, DURATION_IN_FY: fy, PROFIT_CENTER: profitCenter },
          sort,
        },
      }),
    }),
    getSignedUrlForIncentivePayoutExcel: builder.query<IUploadImageUrlResponse, void>({
      query: () => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.PAYOUT}${INCENTIVE_URLS.HISTORY}/${PREVIEW_URLS.SHEET_UPLOAD}`,
      }),
    }),

    validateIncentivePayoutSheet: builder.mutation<IValidateSheetResponse, string>({
      query: (sheetId) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.PAYOUT}${INCENTIVE_URLS.HISTORY}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.SHEET_VALIDATE}`,
        method: "POST",
        body: { sheetId },
      }),
    }),
    syncIncentivePayoutSheet: builder.mutation<GenericResponse<null>, string>({
      query: (sheetId) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.PAYOUT}${INCENTIVE_URLS.HISTORY}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.SYNC}`,
        method: "POST",
      }),
      invalidatesTags: ["IncentiveList"],
    }),
    getIncentivePayoutSheet: builder.query<IGetPreviewResponse, string>({
      query: (sheetId) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.PAYOUT}${INCENTIVE_URLS.HISTORY}/${PREVIEW_URLS.SHEET}/${sheetId}`,
        method: "GET",
      }),
      providesTags: ["IncentiveList"],
    }),

    getSignedUrlForTDSConfirmationExcel: builder.query<IUploadImageUrlResponse, void>({
      query: () => ({
        url: `${INVOICES_URLS.WIP_INVOICE}${INCENTIVE_URLS.TDS}/${PREVIEW_URLS.SHEET_UPLOAD}`,
      }),
    }),

    validateTDSConfirmationSheet: builder.mutation<IValidateSheetResponse, string>({
      query: (sheetId) => ({
        url: `${INVOICES_URLS.WIP_INVOICE}${INCENTIVE_URLS.TDS}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.SHEET_VALIDATE}`,
        method: "POST",
        body: { sheetId },
      }),
    }),
    syncTDSConfirmationSheet: builder.mutation<GenericResponse<null>, string>({
      query: (sheetId) => ({
        url: `${INVOICES_URLS.WIP_INVOICE}${INCENTIVE_URLS.TDS}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.SYNC}`,
        method: "POST",
      }),
      invalidatesTags: ["IncentiveList"],
    }),
    getTDSConfirmationSheet: builder.query<IGetPreviewResponse, string>({
      query: (sheetId) => ({
        url: `${INVOICES_URLS.WIP_INVOICE}${INCENTIVE_URLS.TDS}/${PREVIEW_URLS.SHEET}/${sheetId}`,
        method: "GET",
      }),
      providesTags: ["IncentiveList"],
    }),
    getSignedUrlForEffectiveCTCExcel: builder.query<IUploadImageUrlResponse, void>({
      query: () => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.SALARY}/${PREVIEW_URLS.SHEET_UPLOAD}`,
      }),
    }),

    validateEffectiveCTCSheet: builder.mutation<IValidateSheetResponse, string>({
      query: (sheetId) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.SALARY}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.SHEET_VALIDATE}`,
        method: "POST",
        body: { sheetId },
      }),
    }),
    syncEffectiveCTCSheet: builder.mutation<GenericResponse<null>, string>({
      query: (sheetId) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.SALARY}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.SYNC}`,
        method: "POST",
      }),
      invalidatesTags: ["IncentiveList"],
    }),
    getEffectiveCTCSheet: builder.query<IGetPreviewResponse, string>({
      query: (sheetId) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.SALARY}/${PREVIEW_URLS.SHEET}/${sheetId}`,
        method: "GET",
      }),
      providesTags: ["IncentiveList"],
    }),
    exportPendingTDSExcel: builder.mutation<IBrochureResponse, void>({
      query: () => ({
        url: `${INVOICES_URLS.WIP_INVOICE}${INCENTIVE_URLS.TDS}${INVOICES_URLS.EXPORT}`,
        method: "POST",
      }),
    }),
    exportBalancePayableIncentiveExcel: builder.mutation<IBrochureResponse, void>({
      query: () => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.INCENTIVE}${INCENTIVE_URLS.BALANCE_PAYABLE}${INCENTIVE_URLS.LIST}${INVOICES_URLS.EXPORT}`,
        method: "POST",
      }),
    }),
    incentiveAnalytics: builder.query<IIncentiveAnalyticsResponse, IFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `${INCENTIVE_URLS.INCENTIVE_BASE}${INCENTIVE_URLS.INCENTIVE}${INCENTIVE_URLS.ANALYTICS}`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
  }),
});

export const {
  useIncentiveListingFiltersQuery,
  useGetIncentiveListQuery,
  useExportIncentiveReportExcelMutation,
  useFetchCollectionHistoryTableQuery,
  useFetchPaymentHistoryTableQuery,
  useLazyGetSignedUrlForIncentivePayoutExcelQuery,
  useValidateIncentivePayoutSheetMutation,
  useSyncIncentivePayoutSheetMutation,
  useGetIncentivePayoutSheetQuery,
  useLazyGetSignedUrlForTDSConfirmationExcelQuery,
  useValidateTDSConfirmationSheetMutation,
  useSyncTDSConfirmationSheetMutation,
  useGetTDSConfirmationSheetQuery,
  useLazyGetSignedUrlForEffectiveCTCExcelQuery,
  useValidateEffectiveCTCSheetMutation,
  useSyncEffectiveCTCSheetMutation,
  useGetEffectiveCTCSheetQuery,
  useExportPendingTDSExcelMutation,
  useExportBalancePayableIncentiveExcelMutation,
  useIncentiveKPITilesQuery,
  useIncentiveAnalyticsQuery,
  useFetchRevenueHistoryTableQuery,
} = incentivesApi;
