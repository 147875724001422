import { IWip } from "app/utils/interfaces/index.interface";
import { ANALYTICS_URLS, WIP_URLS } from "constants/ApiUrls";
import { FILTER_TYPES } from "constants/ListingEnums";

import { api, GenericResponse, paginationParams } from "./api";
import { ISortBody } from "./invoices";
import { IPaginationParam } from "./notificationsApi";

export interface IAnalyticsFilters {
  ANALYTICS_DURATION: string | number;
  REGIONS: string[];
  SURVEYORS: string[];
  MONTHS_DURATION?: string[];
  WIP_APPROVED_TAB: string;
  INVOICE_APPROVED_TAB: string;
  MARKET_TYPE_TAB: string;
  INCENTIVE_TYPE_TAB: string;
  DURATION_IN_FY: string;
  BRAND_MANAGER: string[];
  PROFIT_CENTER: string[];
}

export interface IAnalyticsFiltersRequest {
  selectedFilters: IAnalyticsFilters;
  key?: string;
  defaultVal?: boolean;
}

export interface ICollectionAnalyticsFiltersRequest {
  selectedFilters: IAnalyticsFilters;
  isDetails?: boolean;
  isAnalytics?: boolean;
  defaultVal?: boolean;
}
export interface IAnalyticsRequestWithFilters {
  filters: IAnalyticsFilters;
  uid?: string;
  query?: string;
  projectId?: string;
}

export interface IAnalyticsFiltersAndParamsRequest extends IAnalyticsRequestWithFilters {
  params: paginationParams;
}

export interface IAnalyticsSortRequest extends IAnalyticsFiltersAndParamsRequest {
  sort?: ISortBody;
}

export interface IAnalyticsTotal {
  inDateRange: {
    created: number;
    updated: number;
    draft: number;
    expired: number;
    loiSigned: number;
  };
  daysChange: {
    created: number;
    updated: number;
    draft: number;
    expired: number;
    loiSigned: number;
  };
}

export interface IAgentAnalyticsTotals {
  requirements: {
    inDateRange: {
      created: number;
      createdInLastMonth: number;
      with2Options: number;
      with4Matches: number;
      loiSigned: number;
    };
    daysChange: {
      days: number;
      created: number;
      createdInLastMonth: number;
      with2Options: number;
      with4Matches: number;
      loiSigned: number;
    };
  };
}
export interface IWIpAnalyticsTotal {
  totals: {
    target: number;
    projection: number;
    flash: number;
    revenue: number;
  };
}
export interface ISurveyorAnalyticsUser {
  regionName: string;
  userId: string;
  userName: string;
  analytics: {
    created: number;
    expired: number;
    monthlyAverage: number;
  };
}

export interface IAgentAnalyticsUser extends Omit<ISurveyorAnalyticsUser, "analytics"> {
  regionId: string;
  requirements: {
    created: number;
    with2Options: number;
    loiSigned: number;
  };
}

export interface ISurveyorRegion {
  regionName: string;
  regionId: string;
  surveyorsCount: number;
  analytics: {
    created: number;
    expired: number;
    monthlyAverage: number;
  };
}

export interface IAgentAnalyticsRegion {
  regionId: string;
  regionName: string;
  brandManagersCount: number;
  requirements: {
    created: number;
    with2Options: number;
    loiSigned: number;
  };
}

export interface IFilterData {
  id: string;
  title: string;
}

export type valueType = string | string[];
export interface IFilter {
  id: string;
  title: string;
  type: FILTER_TYPES;
  defaultValue: valueType;
  selected: valueType;
  data: IFilterData[];
  hasAll?: boolean;
}

export interface ITableObj {
  [key: string]: string | number;
}

export interface IAnalyticsTable {
  headers: ITableObj;
  rows: ITableObj[];
  summaryRow?: ITableObj[];
  paginationParams: IPaginationParam;
}

export interface IWipRegionAnalytics {
  region: {
    _id: string;
    groupName: string;
  };
  balance: number;
  target: number;
  revenue: number;
  bmCount: number;
}
export interface IWipAgentAnalytics {
  region: {
    _id: string;
    name: string;
  };
  brandManager: {
    _id: string;
    name: string;
  };
  balance: number;
  revenue: number;
  target: number;
}

export interface IWipScorecardAnalytics {
  _id: string;
  region: {
    _id: string;
    groupName: string;
  };
  monthlyCumulatedData: {
    [key: string]: {
      current: number;
      okay: number;
      target: number;
      flash: number;
    };
  };
}

export type IAnalyticsTotalResponse = GenericResponse<IAnalyticsTotal>;
export type IAnalyticsUsersResponse = GenericResponse<ISurveyorAnalyticsUser[]>;
export type IAnalyticsRegionResponse = GenericResponse<ISurveyorRegion[]>;
export type IFilterListResponse = GenericResponse<{
  filters: IFilter[];
  moreFilters?: IFilter[];
}>;
export type IAnalyticsTableResponse = GenericResponse<IAnalyticsTable>;

export type IRequirementTableResponse = GenericResponse<{
  table: IAnalyticsTable;
  paginationParams: IPaginationParam;
}>;

export type IAgentAnalyticsUserResponse = GenericResponse<{
  users: IAgentAnalyticsUser[];
  table: IAnalyticsTable;
}>;

export type IAgentAnalyticsTotalsResponse = GenericResponse<{
  totals: IAgentAnalyticsTotals;
}>;
export interface ICollectedAmountObj {
  collected: number;
  collectedWithoutGst: number;
}
export type ICollectionAnalyticsTotalsResponse = GenericResponse<{
  totals: {
    collectedYTD: ICollectedAmountObj;
    collectedMTD: ICollectedAmountObj;
    collected: ICollectedAmountObj;
    outstanding: number;
    invoiceAmount: number;
    issuesAmount: number;
    legalAmount: number;
    writtenOffAmount: number;
    badDebtAmount: number;
  };
}>;

export type IAgentAnalyticsRegionResponse = GenericResponse<{
  regionalAnalytics: IAgentAnalyticsRegion[];
}>;

export type IWipAnalyticsRegionResponse = GenericResponse<{
  regionalAnalytics: IWipRegionAnalytics[];
}>;

export type IWipAgentAnalyticsResponse = GenericResponse<{
  userAnalytics: IWipAgentAnalytics[];
}>;

export type IWipWIPAnalyticsTotalsResponse = GenericResponse<IWIpAnalyticsTotal>;

export type IWipListResponse = GenericResponse<{
  wips: IWip[];
  paginationParams: IPaginationParam;
}>;

export type IWipScorecardAnalyticsResponse = GenericResponse<{
  regionalScorecardData: IWipScorecardAnalytics[];
}>;

export interface ISuggestedWIP {
  _id: string;
  score: number;
  highlight: TrustedHTML[];
  projectId: string;
  cityId: string;
  landLordName: string;
}

export type SuggestedWIPResponse = GenericResponse<{ wips: ISuggestedWIP[] }>;

export const analyticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    analyticsFilters: builder.query<IFilterListResponse, IAnalyticsFiltersRequest>({
      query: ({ selectedFilters, key }) => ({
        url: `${ANALYTICS_URLS.ANALYTICS}/${key}${ANALYTICS_URLS.FILTERS}`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    wipAnalyticsFilters: builder.query<IFilterListResponse, IAnalyticsFiltersRequest>({
      query: ({ selectedFilters, key, defaultVal }) => ({
        url: `${ANALYTICS_URLS.ANALYTICS}${ANALYTICS_URLS.WIP}${key}${ANALYTICS_URLS.FILTERS}`,
        method: "POST",
        body: { selectedFilters, defaultVal },
      }),
    }),
    analyticsTotal: builder.query<IAnalyticsTotalResponse, IAnalyticsRequestWithFilters>({
      query: ({ filters, uid, query }) => ({
        url: ANALYTICS_URLS.ANALYTICS_TOTAL,
        method: "POST",
        body: { selectedFilters: filters, uid, query },
      }),
    }),
    userAnalyticsTable: builder.query<IAnalyticsTableResponse, IAnalyticsSortRequest>({
      query: ({ filters, params, sort }) => ({
        url: ANALYTICS_URLS.ANALYTICS_USER_TABLE,
        method: "POST",
        body: { selectedFilters: filters, sort },
        params,
      }),
    }),
    analyticsRegions: builder.query<IAnalyticsRegionResponse, IAnalyticsFiltersAndParamsRequest>({
      query: ({ filters, params }) => ({
        url: ANALYTICS_URLS.ANALYTICS_REGIONS,
        method: "POST",
        body: { selectedFilters: filters },
        params,
      }),
    }),
    analyticsUsers: builder.query<IAnalyticsUsersResponse, IAnalyticsFiltersAndParamsRequest>({
      query: ({ filters, params }) => ({
        url: ANALYTICS_URLS.ANALYTICS_USERS,
        method: "POST",
        body: { selectedFilters: filters },
        params,
      }),
    }),
    agentAnalyticsUsers: builder.query<IAgentAnalyticsUserResponse, IAnalyticsRequestWithFilters>({
      query: ({ filters }) => ({
        url: ANALYTICS_URLS.AGENT_ANALYTICS_USERS,
        method: "POST",
        body: { selectedFilters: filters },
        params: { pageNo: 1, pageLimit: 5 },
      }),
    }),
    agentAnalyticsTotals: builder.query<IAgentAnalyticsTotalsResponse, IAnalyticsRequestWithFilters>({
      query: ({ filters }) => ({
        url: ANALYTICS_URLS.AGENT_ANALYTICS_TOTALS,
        method: "POST",
        body: { selectedFilters: filters },
      }),
    }),
    analyticsRequirementTable: builder.query<IRequirementTableResponse, IAnalyticsSortRequest>({
      query: ({ filters, params, sort }) => ({
        url: ANALYTICS_URLS.REQUIREMENT_TABLE,
        method: "POST",
        body: { selectedFilters: filters, sort },
        params,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { filters } = queryArgs;
        return { ...filters };
      },
      merge: (currentCache, newItems) => {
        const isPageOne = newItems.data?.paginationParams?.previous === null;
        if (newItems.success && currentCache?.data?.table && newItems.data && !isPageOne) {
          currentCache.data?.table?.rows?.push(...(newItems.data?.table?.rows || []));
          currentCache.data.paginationParams = newItems.data.paginationParams;
        } else {
          return newItems;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    agentAnalyticsRegion: builder.query<IAgentAnalyticsRegionResponse, IAnalyticsRequestWithFilters>({
      query: ({ filters }) => ({
        url: ANALYTICS_URLS.AGENT_ANALYTICS_REGION,
        method: "POST",
        body: { selectedFilters: filters },
        params: { pageNo: 1, pageLimit: 5 },
      }),
    }),
    wipList: builder.query<IWipListResponse, IAnalyticsSortRequest>({
      query: ({ filters, params, sort, query, uid }) => ({
        url: ANALYTICS_URLS.WIP_LIST,
        method: "POST",
        body: { selectedFilters: filters, sort, query, uid },
        params,
      }),
      providesTags: ["WipList"],
    }),
    wipAnalyticsRegions: builder.query<IWipAnalyticsRegionResponse, IAnalyticsFiltersAndParamsRequest>({
      query: ({ filters, params }) => ({
        url: ANALYTICS_URLS.WIP_ANALYTICS_REGION,
        method: "POST",
        body: { selectedFilters: filters },
        params,
      }),
    }),
    wipAnalyticsAgents: builder.query<IWipAgentAnalyticsResponse, IAnalyticsFiltersAndParamsRequest>({
      query: ({ filters, params }) => ({
        url: ANALYTICS_URLS.WIP_ANALYTICS_AGENT,
        method: "POST",
        body: { selectedFilters: filters },
        params,
      }),
    }),
    wipAnalyticsTotals: builder.query<IWipWIPAnalyticsTotalsResponse, IAnalyticsRequestWithFilters>({
      query: ({ filters }) => ({
        url: ANALYTICS_URLS.WIP_ANALYTICS_TOTALS,
        method: "POST",
        body: { selectedFilters: filters },
      }),
      providesTags: ["wipProjection"],
    }),
    wipSummary: builder.query<IWipListResponse, IAnalyticsRequestWithFilters>({
      query: ({ filters, query, uid }) => ({
        url: ANALYTICS_URLS.WIP_SUMMARY,
        method: "POST",
        body: { selectedFilters: filters, query, uid },
      }),
      providesTags: ["WipList"],
    }),
    wipScorecard: builder.query<IWipScorecardAnalyticsResponse, IAnalyticsRequestWithFilters>({
      query: ({ filters }) => ({
        url: `${ANALYTICS_URLS.ANALYTICS}${ANALYTICS_URLS.WIP}${ANALYTICS_URLS.WIP_SCORECARD}`,
        method: "POST",
        body: { selectedFilters: filters },
      }),
    }),
    collectionsAnalyticsFilters: builder.query<IFilterListResponse, ICollectionAnalyticsFiltersRequest>({
      query: ({ selectedFilters, isAnalytics, isDetails, defaultVal }) => ({
        url: `${isAnalytics ? ANALYTICS_URLS.ANALYTICS : ""}${WIP_URLS.WIP}${WIP_URLS.INVOICE}${
          isDetails ? ANALYTICS_URLS.WIP_DETAILS : ""
        }${ANALYTICS_URLS.FILTERS}`,
        method: "POST",
        body: { selectedFilters, defaultVal },
      }),
    }),
    collectionAnalyticsTotals: builder.query<ICollectionAnalyticsTotalsResponse, IAnalyticsRequestWithFilters>({
      query: ({ filters }) => ({
        url: ANALYTICS_URLS.COLLECTIONS_ANALYTICS_TOTAL,
        method: "POST",
        body: { selectedFilters: filters },
      }),
    }),
    getTotalWIPCount: builder.query<GenericResponse<{ totalWIPCount: number }>, string>({
      query: (approved) => ({
        url: ANALYTICS_URLS.WIP_TOTAL_COUNT,
        method: "GET",
        params: {
          approvedTab: approved,
        },
      }),
    }),
    getTotalInvoiceCount: builder.query<GenericResponse<{ totalWIPInvoiceCount: number }>, void>({
      query: () => ({
        url: ANALYTICS_URLS.INVOICE_TOTAL_COUNT,
        method: "GET",
      }),
    }),
    getWIPSearchSuggestions: builder.mutation<SuggestedWIPResponse, { query: string; isConsulting: boolean }>({
      query: ({ query, isConsulting }) => ({
        url: isConsulting ? ANALYTICS_URLS.CONSULTING_WIP_AUTO_SUGGEST : ANALYTICS_URLS.WIP_AUTO_SUGGEST,
        method: "POST",
        body: {
          query,
          limit: 7,
        },
      }),
    }),
  }),
});

export const {
  useAnalyticsFiltersQuery,
  useWipAnalyticsFiltersQuery,
  useAnalyticsTotalQuery,
  useUserAnalyticsTableQuery,
  useAnalyticsRegionsQuery,
  useAnalyticsUsersQuery,
  useAgentAnalyticsUsersQuery,
  useAnalyticsRequirementTableQuery,
  useAgentAnalyticsRegionQuery,
  useWipListQuery,
  useWipAnalyticsRegionsQuery,
  useWipAnalyticsAgentsQuery,
  useWipAnalyticsTotalsQuery,
  useWipSummaryQuery,
  useWipScorecardQuery,
  useAgentAnalyticsTotalsQuery,
  useCollectionsAnalyticsFiltersQuery,
  useCollectionAnalyticsTotalsQuery,
  useGetTotalWIPCountQuery,
  useGetTotalInvoiceCountQuery,
  useGetWIPSearchSuggestionsMutation,
} = analyticsApi;
