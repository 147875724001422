import { ArPostUpload, ArUploadDragger } from "@anarock/ar-common-react";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

import styles from "./index.module.scss";

export const UploadModal = ({
  sheetId,
  handleUpload,
  sheetName,
  setSheetId,
  showDeleteIcon = true,
}: {
  sheetId?: string;
  handleUpload: (props: any) => void;
  sheetName: string;
  setSheetId: React.Dispatch<React.SetStateAction<string | undefined>>;
  showDeleteIcon?: boolean;
}) => {
  return sheetId ? (
    <div className={styles.height50}>
      <ArPostUpload title={`${sheetName}`} deleteFile={() => setSheetId(undefined)} showDeleteIcon={showDeleteIcon} />
    </div>
  ) : (
    <div className={styles.fixedHeight}>
      <ArUploadDragger
        title={`${STRING_CONSTANTS.CLICK_TO_UPLOAD} ${sheetName} ${STRING_CONSTANTS.SHEET}`}
        className="pt-16 pb-16"
        customRequest={(options) => handleUpload(options)}
        maxCount={1}
        accept={STRING_CONSTANTS.ACCEPT_EXCEL_FILE}
        multiple={false}
      />
    </div>
  );
};
