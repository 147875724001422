import { IAnalyticsFilters } from "app/services/analytics";
import {
  useExportBalancePayableIncentiveExcelMutation,
  useExportIncentiveReportExcelMutation,
  useExportPendingTDSExcelMutation,
} from "app/services/incentives";
import { useExportInvoicePendingExcelMutation, useExportIRFExcelMutation } from "app/services/invoices";
import { ISelectedFilters } from "app/services/listingPageApi";
import {
  useGenerateBrochureForShopMutation,
  useGenerateExcelForWipMutation,
  useGenerateZipForShopMutation,
  useGetJobStatusQuery,
} from "app/services/optionListApi";
import {
  useExportAllocationReportExcelMutation,
  useExportCollectionReportExcelMutation,
  useExportRevenueReportExcelMutation,
} from "app/services/reports";
import { useToastService } from "components/toastService";
import { WIP_URLS } from "constants/ApiUrls";
import { BROCHURE_STATUS } from "constants/ListingEnums";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { useEffect, useState } from "react";

import { downloadFileFromUrl, isNotNullorUndefined, isNullOrUndefiend } from "./helperFunctions";
import { useFiltersWithQuery } from "./useFiltersHook";

export const useGenerateBrochureHook = (shopId?: string) => {
  const { selectedFilters } = useFiltersWithQuery<ISelectedFilters>();
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const [overAllLoading, setOverAllLoading] = useState<boolean>(false);
  const [generateBrochureApi, { isLoading: generateBrochureLoading }] = useGenerateBrochureForShopMutation();
  const [generateZIPApi, { isLoading: generateZIPLoading }] = useGenerateZipForShopMutation();
  const [generateExcelApi, { isLoading: generateExcelLoading }] = useGenerateExcelForWipMutation();
  const [generateIRFExcel, { isLoading: generateIRFExcelLoading }] = useExportIRFExcelMutation();
  const [generateInvoicePendingExcel, { isLoading: generateInvoicePendingExcelLoading }] =
    useExportInvoicePendingExcelMutation();
  const [generateRevenueReportExcel, { isLoading: generateRevenueReportExcelLoading }] =
    useExportRevenueReportExcelMutation();

  const [generateCollectionReportExcel, { isLoading: generateCollectionReportExcelLoading }] =
    useExportCollectionReportExcelMutation();

  const [generateRevenueAllocationReportExcel, { isLoading: generateRevenueAllocationReportExcelLoading }] =
    useExportAllocationReportExcelMutation();

  const [generateIncentiveReportExcel, { isLoading: generateIncentiveReportExcelLoading }] =
    useExportIncentiveReportExcelMutation();

  const [generatePendingTDSExcel, { isLoading: generatePendingTDSExcelLoading }] = useExportPendingTDSExcelMutation();

  const [generateBalancePayableIncentiveExcel, { isLoading: generateBalancePayableIncentiveExcelLoading }] =
    useExportBalancePayableIncentiveExcelMutation();

  const { errorToast, inProgressToast } = useToastService();

  const {
    data: jobData,
    isError,
    isFetching,
  } = useGetJobStatusQuery(jobId || "", {
    refetchOnMountOrArgChange: true,
    pollingInterval: 3000,
    skip: isNullOrUndefiend(jobId),
  });

  const apiLoadings =
    isFetching ||
    generateBrochureLoading ||
    generateZIPLoading ||
    generateExcelLoading ||
    generateIRFExcelLoading ||
    generateInvoicePendingExcelLoading ||
    generateRevenueReportExcelLoading ||
    generateCollectionReportExcelLoading ||
    generateRevenueAllocationReportExcelLoading ||
    generateIncentiveReportExcelLoading ||
    generatePendingTDSExcelLoading ||
    generateBalancePayableIncentiveExcelLoading;

  const loading = apiLoadings || overAllLoading;

  const generateBorchure = async () => {
    if (isNotNullorUndefined(jobId) || loading) {
      errorToast(STRING_CONSTANTS.PLEASE_WAIT_SOMETIME);
    }
    try {
      const res = await generateBrochureApi(shopId as string).unwrap();
      if (res?.success) {
        setJobId(res?.data?.jobId);
        setOverAllLoading(true);
      } else {
        errorToast(res?.reason || STRING_CONSTANTS.ERROR_GENERATING_BROCHURE);
      }
    } catch {
      errorToast(STRING_CONSTANTS.ERROR_GENERATING_BROCHURE);
    }
  };

  const generateZIPFile = async (files: string[]) => {
    if (isNotNullorUndefined(jobId) || loading) {
      errorToast(STRING_CONSTANTS.PLEASE_WAIT_SOMETIME);
    }
    try {
      const res = await generateZIPApi({ shopId, files }).unwrap();
      if (res?.success) {
        setJobId(res?.data?.jobId);
        setOverAllLoading(true);
      } else {
        errorToast(res?.reason || STRING_CONSTANTS.ERROR_GENERATING_ZIP);
      }
    } catch {
      errorToast(STRING_CONSTANTS.ERROR_GENERATING_ZIP);
    }
  };

  const generateExcelSheet = async (selectedFilters: IAnalyticsFilters, isWip: boolean) => {
    if (isNotNullorUndefined(jobId) || loading) {
      errorToast(STRING_CONSTANTS.PLEASE_WAIT_SOMETIME);
    }
    const url = isWip ? WIP_URLS.EXPORT_WIP_SHEET : WIP_URLS.EXPORT_COLLECTION_SHEET;
    try {
      const res = await generateExcelApi({ url, selectedFilters }).unwrap();
      if (res?.success) {
        setJobId(res?.data?.jobId);
        inProgressToast(
          `${STRING_CONSTANTS.EXPORTING} ${isWip ? STRING_CONSTANTS.WIP : STRING_CONSTANTS.COLLECTION} ${
            STRING_CONSTANTS.SHEET_IN_PROGRESS
          }`
        );
        setOverAllLoading(true);
      } else {
        errorToast(res?.reason || STRING_CONSTANTS.ERROR_GENERATING_SHEET);
      }
    } catch {
      errorToast(STRING_CONSTANTS.ERROR_GENERATING_SHEET);
    }
  };

  const generateIRFExcelSheet = async (irfId: string) => {
    if (isNotNullorUndefined(jobId) || loading) {
      errorToast(STRING_CONSTANTS.PLEASE_WAIT_SOMETIME);
    }
    try {
      const res = await generateIRFExcel(irfId).unwrap();
      if (res?.success) {
        setJobId(res?.data?.jobId);
        inProgressToast(`Sheet Generation in Progress`);
        setOverAllLoading(true);
      } else {
        errorToast(res?.reason || STRING_CONSTANTS.ERROR_GENERATING_SHEET);
      }
    } catch {
      errorToast(STRING_CONSTANTS.ERROR_GENERATING_SHEET);
    }
  };

  const generateInvoicePendingExcelSheet = async () => {
    if (isNotNullorUndefined(jobId) || loading) {
      errorToast(STRING_CONSTANTS.PLEASE_WAIT_SOMETIME);
    }
    try {
      const res = await generateInvoicePendingExcel().unwrap();
      if (res?.success) {
        setJobId(res?.data?.jobId);
        inProgressToast(`Sheet Generation in Progress`);
        setOverAllLoading(true);
      } else {
        errorToast(res?.reason || STRING_CONSTANTS.ERROR_GENERATING_SHEET);
      }
    } catch {
      errorToast(STRING_CONSTANTS.ERROR_GENERATING_SHEET);
    }
  };

  const generatePendingTDSExcelSheet = async () => {
    if (isNotNullorUndefined(jobId) || loading) {
      errorToast(STRING_CONSTANTS.PLEASE_WAIT_SOMETIME);
    }
    try {
      const res = await generatePendingTDSExcel().unwrap();
      if (res?.success) {
        setJobId(res?.data?.jobId);
        inProgressToast(`Sheet Generation in Progress`);
        setOverAllLoading(true);
      } else {
        errorToast(res?.reason || STRING_CONSTANTS.ERROR_GENERATING_SHEET);
      }
    } catch {
      errorToast(STRING_CONSTANTS.ERROR_GENERATING_SHEET);
    }
  };

  const generateBalancePayableIncentiveExcelSheet = async () => {
    if (isNotNullorUndefined(jobId) || loading) {
      errorToast(STRING_CONSTANTS.PLEASE_WAIT_SOMETIME);
    }
    try {
      const res = await generateBalancePayableIncentiveExcel().unwrap();
      if (res?.success) {
        setJobId(res?.data?.jobId);
        inProgressToast(`Sheet Generation in Progress`);
        setOverAllLoading(true);
      } else {
        errorToast(res?.reason || STRING_CONSTANTS.ERROR_GENERATING_SHEET);
      }
    } catch {
      errorToast(STRING_CONSTANTS.ERROR_GENERATING_SHEET);
    }
  };

  const generateRevenueReportExcelSheet = async () => {
    if (isNotNullorUndefined(jobId) || loading) {
      errorToast(STRING_CONSTANTS.PLEASE_WAIT_SOMETIME);
    }
    try {
      const res = await generateRevenueReportExcel({
        selectedFilters: selectedFilters,
      }).unwrap();
      if (res?.success) {
        setJobId(res?.data?.jobId);
        inProgressToast(STRING_CONSTANTS.REPORT_GENERATION_IN_PROGRESS);
        setOverAllLoading(true);
      } else {
        errorToast(res?.reason || STRING_CONSTANTS.ERROR_GENERATING_REPORT);
      }
    } catch {
      errorToast(STRING_CONSTANTS.ERROR_GENERATING_REPORT);
    }
  };

  const generateCollectionReportExcelSheet = async () => {
    if (isNotNullorUndefined(jobId) || loading) {
      errorToast(STRING_CONSTANTS.PLEASE_WAIT_SOMETIME);
    }
    try {
      const res = await generateCollectionReportExcel({
        selectedFilters: selectedFilters,
      }).unwrap();
      if (res?.success) {
        setJobId(res?.data?.jobId);
        inProgressToast(STRING_CONSTANTS.REPORT_GENERATION_IN_PROGRESS);
        setOverAllLoading(true);
      } else {
        errorToast(res?.reason || STRING_CONSTANTS.ERROR_GENERATING_REPORT);
      }
    } catch {
      errorToast(STRING_CONSTANTS.ERROR_GENERATING_REPORT);
    }
  };
  const generateRevenueAllocationReportExcelSheet = async () => {
    if (isNotNullorUndefined(jobId) || loading) {
      errorToast(STRING_CONSTANTS.PLEASE_WAIT_SOMETIME);
    }
    try {
      const res = await generateRevenueAllocationReportExcel({
        selectedFilters: selectedFilters,
      }).unwrap();
      if (res?.success) {
        setJobId(res?.data?.jobId);
        inProgressToast(STRING_CONSTANTS.REPORT_GENERATION_IN_PROGRESS);
        setOverAllLoading(true);
      } else {
        errorToast(res?.reason || STRING_CONSTANTS.ERROR_GENERATING_REPORT);
      }
    } catch {
      errorToast(STRING_CONSTANTS.ERROR_GENERATING_REPORT);
    }
  };

  const generateIncentiveReportExcelSheet = async () => {
    if (isNotNullorUndefined(jobId) || loading) {
      errorToast(STRING_CONSTANTS.PLEASE_WAIT_SOMETIME);
    }
    try {
      const res = await generateIncentiveReportExcel({
        selectedFilters: selectedFilters,
      }).unwrap();
      if (res?.success) {
        setJobId(res?.data?.jobId);
        inProgressToast(STRING_CONSTANTS.REPORT_GENERATION_IN_PROGRESS);
        setOverAllLoading(true);
      } else {
        errorToast(res?.reason || STRING_CONSTANTS.ERROR_GENERATING_REPORT);
      }
    } catch {
      errorToast(STRING_CONSTANTS.ERROR_GENERATING_REPORT);
    }
  };

  useEffect(() => {
    if (isNullOrUndefiend(jobId) || apiLoadings) {
      return;
    }
    if (isError || !jobData?.success || jobData?.data?.jobDoc?.status === BROCHURE_STATUS.FAILED) {
      errorToast(jobData?.reason || STRING_CONSTANTS.ERROR_DOWNLOADING);
      setJobId(undefined);
      setOverAllLoading(false);
    } else if (jobData?.success && jobData?.data?.jobDoc?.status === BROCHURE_STATUS.PROCESSED) {
      downloadFileFromUrl(jobData?.data?.jobDoc?.exportFileUrl);
      setOverAllLoading(false);
      setJobId(undefined);
    }
  }, [isError, jobData, jobId, shopId, apiLoadings]);

  return {
    generateBorchure,
    loading,
    generateZIPFile,
    generateExcelSheet,
    generateIRFExcelSheet,
    generateInvoicePendingExcelSheet,
    generateRevenueReportExcelSheet,
    generateCollectionReportExcelSheet,
    generateRevenueAllocationReportExcelSheet,
    generateIncentiveReportExcelSheet,
    generatePendingTDSExcelSheet,
    generateBalancePayableIncentiveExcelSheet,
  };
};
