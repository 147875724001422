import { SheetTypeEnum } from "components/ImportSheetModal/index.interface";
import { COLLECTION_URLS, PREVIEW_URLS, WIP_URLS } from "constants/ApiUrls";

import { IAnalyticsTable } from "./analytics";
import { api, GenericResponse } from "./api";
import { IUploadUrl } from "./invoices";

export interface IGetUploadUrlResponse extends IUploadUrl {
  sheetId: string;
}

export type IUploadImageUrlResponse = GenericResponse<{
  uploadSheetURL: IGetUploadUrlResponse;
  uploadInvoiceUrl: IGetUploadUrlResponse;
  sheetId: string;
  zipId: string;
}>;

export interface IValidateSheetBody {
  type: SheetTypeEnum;
  sheetId: string;
}
export interface ISheetReviewCounts {
  matched: number;
  mismatched: number;
  error: number;
  previouslySynced: number;
  total: number;
}
export type IGetPreviewResponse = GenericResponse<{
  counts: ISheetReviewCounts;
  isSync: boolean;
  table: IAnalyticsTable;
}>;

export type IValidateSheetResponse = GenericResponse<{
  isValid: boolean;
  jobId: string;
}>;

export const previewSheetApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSignedUrlForExcel: builder.query<IUploadImageUrlResponse, SheetTypeEnum>({
      query: (type) => ({
        url: `${WIP_URLS.WIP}/${type}/${PREVIEW_URLS.SHEET_UPLOAD}`,
      }),
    }),

    validateSheet: builder.mutation<IValidateSheetResponse, IValidateSheetBody>({
      query: ({ type, sheetId }) => ({
        url: `${WIP_URLS.WIP}/${type}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.SHEET_VALIDATE}`,
        method: "POST",
        body: { sheetId },
      }),
    }),

    getSheet: builder.query<IGetPreviewResponse, IValidateSheetBody>({
      query: ({ type, sheetId }) => ({
        url: `${WIP_URLS.WIP}/${type}/${PREVIEW_URLS.SHEET}/${sheetId}`,
        method: "GET",
      }),
      providesTags: ["sheet"],
    }),

    syncSheet: builder.mutation<GenericResponse<null>, IValidateSheetBody>({
      query: ({ type, sheetId }) => ({
        url: `${WIP_URLS.WIP}/${type}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.SYNC}`,
        method: "POST",
      }),
      invalidatesTags: ["wipProjection", "WipDetails", "WipList", "sheet"],
    }),

    getExportDataUrl: builder.query<GenericResponse<{ googleSheetURL: string }>, IValidateSheetBody>({
      query: ({ type, sheetId }) => ({
        url: `${WIP_URLS.WIP}/${type}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.EXPORT}`,
        method: "GET",
      }),
      providesTags: ["sheet"],
    }),

    getCollectionSheet: builder.query<IGetPreviewResponse, string>({
      query: (sheetId) => ({
        url: `${WIP_URLS.WIP}${COLLECTION_URLS.COLLECTION}/${PREVIEW_URLS.SHEET}/${sheetId}`,
        method: "GET",
      }),
      providesTags: ["sheet"],
    }),

    getCollectionExportDataUrl: builder.query<GenericResponse<{ googleSheetURL: string }>, string>({
      query: (sheetId) => ({
        url: `${WIP_URLS.WIP}${COLLECTION_URLS.COLLECTION}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.EXPORT}`,
        method: "GET",
      }),
      providesTags: ["sheet"],
    }),

    syncCollectionSheet: builder.mutation<GenericResponse<null>, string>({
      query: (sheetId) => ({
        url: `${WIP_URLS.WIP}${COLLECTION_URLS.COLLECTION}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.SYNC}`,
        method: "POST",
      }),
      invalidatesTags: ["wipProjection", "WipDetails", "WipList", "sheet", "collectionProjection"],
    }),

    getSignedUrlForCollectionExcel: builder.query<IUploadImageUrlResponse, void>({
      query: () => ({
        url: `${WIP_URLS.WIP}${COLLECTION_URLS.COLLECTION}/${PREVIEW_URLS.SHEET_UPLOAD}`,
      }),
    }),

    validateCollectionSheet: builder.mutation<IValidateSheetResponse, string>({
      query: (sheetId) => ({
        url: `${WIP_URLS.WIP}${COLLECTION_URLS.COLLECTION}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.SHEET_VALIDATE}`,
        method: "POST",
        body: { sheetId },
      }),
    }),

    getSignedUrlForInvoicesExcel: builder.query<IUploadImageUrlResponse, void>({
      query: () => ({
        url: `${WIP_URLS.WIP}${PREVIEW_URLS.INVOICE}${PREVIEW_URLS.BULK}/${PREVIEW_URLS.SHEET_UPLOAD}`,
      }),
    }),

    getSignedUrlForInvoicesZip: builder.query<IUploadImageUrlResponse, void>({
      query: () => ({
        url: `${WIP_URLS.WIP}${PREVIEW_URLS.INVOICE}${PREVIEW_URLS.BULK}/${PREVIEW_URLS.SHEET_UPLOAD}${PREVIEW_URLS.ZIP}`,
      }),
    }),

    validateInvoiceSheet: builder.mutation<IValidateSheetResponse, { sheetId: string; zipId: string }>({
      query: ({ sheetId, zipId }) => ({
        url: `${WIP_URLS.WIP}${PREVIEW_URLS.INVOICE}${PREVIEW_URLS.BULK}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.ZIP}/${zipId}${PREVIEW_URLS.SHEET_VALIDATE}`,
        method: "POST",
        body: { sheetId },
      }),
    }),

    getInvoicesSheet: builder.query<IGetPreviewResponse, string>({
      query: (sheetId) => ({
        url: `${WIP_URLS.WIP}${PREVIEW_URLS.INVOICE}${PREVIEW_URLS.BULK}/${PREVIEW_URLS.SHEET}/${sheetId}`,
        method: "GET",
      }),
      providesTags: ["sheet"],
    }),

    syncInvoicesSheet: builder.mutation<GenericResponse<null>, string>({
      query: (sheetId) => ({
        url: `${WIP_URLS.WIP}${PREVIEW_URLS.INVOICE}${PREVIEW_URLS.BULK}/${PREVIEW_URLS.SHEET}/${sheetId}${PREVIEW_URLS.SYNC}`,
        method: "POST",
      }),
      invalidatesTags: ["sheet"],
    }),
  }),
});

export const {
  useLazyGetSignedUrlForExcelQuery,
  useValidateSheetMutation,
  useGetSheetQuery,
  useSyncSheetMutation,
  useLazyGetExportDataUrlQuery,
  useGetCollectionSheetQuery,
  useLazyGetCollectionExportDataUrlQuery,
  useSyncCollectionSheetMutation,
  useLazyGetSignedUrlForCollectionExcelQuery,
  useValidateCollectionSheetMutation,
  useLazyGetSignedUrlForInvoicesExcelQuery,
  useLazyGetSignedUrlForInvoicesZipQuery,
  useValidateInvoiceSheetMutation,
  useGetInvoicesSheetQuery,
  useSyncInvoicesSheetMutation,
} = previewSheetApi;
