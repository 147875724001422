import { ArBreadCrumb, ArPagination, ArProgress, ArSegmented, ArTable } from "@anarock/ar-common-react";
import colors from "@anarock/ar-common-react/dist/styles/partials/_colors.module.scss";
import { ArBreadCrumbItem } from "@anarock/ar-common-react/dist/ui-components/ar-breadcrumb";
import ArTooltip from "@anarock/ar-common-react/dist/ui-components/ar-tooltip";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { useAppSelector } from "app/hooks";
import { IAnalyticsFilters } from "app/services/analytics";
import { IIncentive, useGetIncentiveListQuery } from "app/services/incentives";
import { convertToRupee, isNullOrUndefiend } from "app/utils/helperFunctions";
import { TABLE_CONSTANTS } from "constants/helperStrings";
import { INCENTIVE_TYPE_TAB } from "constants/index";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { USER_ROLE_NAME } from "constants/Taboptions";
import { selectAuthUser } from "features/authentication/authSlice";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import styles from "../../index.module.scss";
import IncentiveDropdown from "../IncentiveDropdown";

const IncentiveTable = ({
  showPagination = true,
  showHeading = true,
  showSummary = true,
  showValues = false,
  pageLimit = 10,
}: {
  showPagination?: boolean;
  showHeading?: boolean;
  showSummary?: boolean;
  showValues?: boolean;
  pageLimit?: number;
}) => {
  const [searchParams] = useSearchParams();
  const user = useAppSelector(selectAuthUser);

  const isBMLead = useMemo(() => {
    return (
      user?.subRoles?.includes(USER_ROLE_NAME.BRAND_MANAGER_LEAD) ||
      user?.subRoles?.includes(USER_ROLE_NAME.BRAND_MANAGER_LEAD_MARKER)
    );
  }, [user]);

  const isBMManager = useMemo(() => {
    return (
      user?.subRoles?.includes(USER_ROLE_NAME.BRAND_MANAGER_ASSOCIATE) ||
      user?.subRoles?.includes(USER_ROLE_NAME.BRAND_MANAGER_ASSOCIATE_MARKER)
    );
  }, [user]);

  const isIncentiveTeam = useMemo(() => {
    return user?.subRoles?.includes(USER_ROLE_NAME.INCENTIVE_TEAM);
  }, [user]);

  const [selectedFilters, setSelectedFilters] = useState<IAnalyticsFilters>(
    JSON.parse(searchParams.get("filters") as string) || {}
  );

  const [pageNo, setPageNo] = useState(1);
  const [hideLeadCollectionFields, setHideLeadCollectionFields] = useState(true);
  const [hideAgentCollectionFields, setHideAgentCollectionFields] = useState(true);
  const [hideTeamCollectionFields, setHideTeamCollectionFields] = useState(true);
  const [isVisible, setIsVisible] = useState(showValues);
  const [sortData, setSortData] = useState<{
    field: string | number;
    order: string;
  }>({
    field: "",
    order: "",
  });
  const [isInActive, setIsInActive] = useState(false);

  useEffect(() => {
    setPageNo(1);
    setSelectedFilters(JSON.parse(searchParams.get("filters") as string));
  }, [searchParams]);

  const activityWatcher = useCallback(() => {
    let secondsSinceLastActivity = 0;

    const maxInactivity = 5;

    setInterval(function () {
      secondsSinceLastActivity++;
      if (secondsSinceLastActivity > maxInactivity) {
        setIsInActive(true);
        setIsVisible(false);
        secondsSinceLastActivity = 0;
      }
    }, 1000);

    function activity() {
      secondsSinceLastActivity = 0;
    }

    const activityEvents = ["mousedown", "mousemove", "keydown", "scroll", "touchstart"];

    activityEvents.forEach(function (eventName) {
      document.addEventListener(eventName, activity, true);
    });
  }, []);

  useEffect(() => {
    if (showValues) {
      return;
    }
    activityWatcher();
    return () => {
      const activityEvents = ["mousedown", "mousemove", "keydown", "scroll", "touchstart"];

      activityEvents.forEach(function (eventName) {
        document.removeEventListener(eventName, () => {
          // Do nothing
        });
      });
    };
  }, [activityWatcher]);

  useEffect(() => {
    if (isInActive) {
      setIsInActive(false);
    }
  }, [isInActive]);

  const {
    data: incentivesList,
    isLoading,
    isFetching,
  } = useGetIncentiveListQuery(
    {
      selectedFilters: selectedFilters,
      params: {
        pageNo,
        pageLimit,
      },
      sort: sortData,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isNullOrUndefiend(selectedFilters) || Object.keys(selectedFilters).length === 0,
    }
  );

  const isAgent = useMemo(() => {
    return selectedFilters?.["INCENTIVE_TYPE_TAB"] === INCENTIVE_TYPE_TAB.BRAND_MANAGER_ASSOCIATE;
  }, [selectedFilters]);

  const isCityLead = useMemo(() => {
    return selectedFilters?.["INCENTIVE_TYPE_TAB"] === INCENTIVE_TYPE_TAB.BRAND_MANAGER_LEAD;
  }, [selectedFilters]);

  const columns = [
    {
      title: "#",
      dataIndex: "slNo",
      key: "slNo",
      fixed: TABLE_CONSTANTS.LEFT,
      width: 30,
      render: (text: string, record: IIncentive, index: number) => (
        <>
          <div>{showSummary ? (index === 0 ? "#" : (pageNo - 1) * pageLimit + index) : index + 1}</div>
        </>
      ),
    },
    ...(isBMLead || (isIncentiveTeam && isAgent)
      ? [
          {
            title: STRING_CONSTANTS.AGENT,
            dataIndex: "agentName",
            key: "agentName",
            width: 100,
            sorter: true,
            fixed: TABLE_CONSTANTS.LEFT,
            render: (text: string, record: IIncentive) => (
              <div className={styles.flexTableCell}>{record?.agentName || "-"}</div>
            ),
          },
        ]
      : []),
    ...(isBMManager || (isIncentiveTeam && isCityLead)
      ? [
          {
            title: STRING_CONSTANTS.CITY_LEAD,
            dataIndex: "agentName",
            key: "agentName",
            width: 100,
            sorter: true,
            fixed: TABLE_CONSTANTS.LEFT,
            render: (text: string, record: IIncentive) => (
              <div className={styles.flexTableCell}>{record?.agentName || "-"}</div>
            ),
          },
        ]
      : []),
    {
      title: STRING_CONSTANTS.PROFIT_CENTER,
      dataIndex: "profitCenter",
      key: "profitCenter",
      width: 100,
      sorter: true,
      render: (text: string, record: IIncentive) => (
        <div className={styles.flexTableCell}>{record?.profitCenter || "-"}</div>
      ),
    },
    ...(isBMManager || (isIncentiveTeam && isCityLead)
      ? [
          {
            title: (
              <div className={styles.flexTableCell}>
                {STRING_CONSTANTS.CITY_TYPE}{" "}
                <ArTooltip
                  heading=""
                  customTitle={
                    <>
                      <div>{STRING_CONSTANTS.TIER_1_REVENUE}</div>
                      <div>{STRING_CONSTANTS.TIER_2_REVENUE}</div>
                    </>
                  }
                >
                  <div className={styles.iconsPadding}>
                    <InfoCircleOutlined />
                  </div>
                </ArTooltip>
              </div>
            ),
            dataIndex: "teamTier",
            key: "teamTier",
            width: 100,
            sorter: true,
            showSorterTooltip: false,
            render: (text: string, record: IIncentive) => (
              <div className={styles.flexTableCell}>{record?.teamTier || "-"}</div>
            ),
          },
        ]
      : []),
    {
      title: (
        <div className={styles.flexTableCell}>
          <span>{STRING_CONSTANTS.TEAM_COLLECTION}</span>
          {hideTeamCollectionFields ? (
            <PlusCircleTwoTone
              className={`${styles.plusMinusIcon} ${styles.paddingRightSmall}`}
              twoToneColor={colors["primary-400"]}
              onClick={(e) => {
                e.stopPropagation();
                setHideTeamCollectionFields(!hideTeamCollectionFields);
              }}
            />
          ) : (
            <MinusCircleTwoTone
              className={`${styles.plusMinusIcon} ${styles.paddingRightSmall}`}
              twoToneColor={colors["primary-400"]}
              onClick={(e) => {
                e.stopPropagation();
                setHideTeamCollectionFields(!hideTeamCollectionFields);
              }}
            />
          )}
        </div>
      ),
      dataIndex: "teamCollection",
      key: "teamCollection",
      width: 100,
      sorter: true,
      render: (text: string, record: IIncentive) => (
        <div className={styles.flexTableCell}>
          <div>{record?.teamCollection || "-"}</div>
        </div>
      ),
    },
    {
      title: STRING_CONSTANTS.TEAM_NET_REVENUE,
      dataIndex: "teamRevenue",
      key: "teamRevenue",
      width: 100,
      sorter: true,
      hidden: hideTeamCollectionFields,
      render: (text: string, record: IIncentive, index: number) => (
        <div className={styles.flexTableCell}>
          <div>{record?.teamRevenue || "-"}</div>
          {index === 0 ? null : (
            <ArTooltip
              heading=""
              customTitle={
                <>
                  <div>{STRING_CONSTANTS.REVENUE_WITH_DOCKING}</div>
                  <div> {record?.teamDocketedRevenue || "-"}</div>
                </>
              }
            >
              <div className={styles.iconsPadding}>
                <InfoCircleOutlined />
              </div>
            </ArTooltip>
          )}
        </div>
      ),
    },
    {
      title: STRING_CONSTANTS.TEAM_EFFECTIVE_CTC,
      dataIndex: "agent",
      key: "agent",
      width: 100,
      sorter: true,
      hidden: hideTeamCollectionFields,
      render: (text: string, record: IIncentive) => (
        <div className={styles.flexTableCell}>{record?.teamEffectiveCtc || "-"}</div>
      ),
    },
    ...(isBMLead || (isIncentiveTeam && isAgent)
      ? [
          {
            title: (
              <div className={styles.flexTableCell}>
                <span>{STRING_CONSTANTS.AGENT_COLLECTION}</span>
                {hideAgentCollectionFields ? (
                  <PlusCircleTwoTone
                    className={`${styles.plusMinusIcon} ${styles.paddingRightSmall}`}
                    twoToneColor={colors["primary-400"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      setHideAgentCollectionFields(!hideAgentCollectionFields);
                    }}
                  />
                ) : (
                  <MinusCircleTwoTone
                    className={`${styles.plusMinusIcon} ${styles.paddingRightSmall}`}
                    twoToneColor={colors["primary-400"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      setHideAgentCollectionFields(!hideAgentCollectionFields);
                    }}
                  />
                )}
              </div>
            ),
            dataIndex: "agentCollection",
            key: "agentCollection",
            width: 100,
            sorter: true,
            render: (text: string, record: IIncentive) => (
              <div className={styles.flexTableCell}>{record?.agentCollection || "-"}</div>
            ),
          },
          {
            title: STRING_CONSTANTS.AGENT_NET_REVENUE,
            dataIndex: "agentRevenue",
            key: "agentRevenue",
            width: 100,
            sorter: true,
            hidden: hideAgentCollectionFields,
            render: (text: string, record: IIncentive) => (
              <div className={styles.flexTableCell}>
                <div>{record?.agentRevenue || "-"}</div>
                <ArTooltip
                  heading=""
                  customTitle={
                    <>
                      <div>{STRING_CONSTANTS.REVENUE_WITH_DOCKING}</div>
                      <div> {record?.agentDocketedRevenue || "-"}</div>
                    </>
                  }
                >
                  <div className={styles.iconsPadding}>
                    <InfoCircleOutlined />
                  </div>
                </ArTooltip>
              </div>
            ),
          },
          // {
          //   title: STRING_CONSTANTS.AGENT_EFFECTIVE_CTC,
          //   dataIndex: "agent",
          //   key: "agent",
          //   width: 100,
          //   sorter: true,
          //   hidden: hideAgentCollectionFields,
          //   render: (text: string, record: IIncentive) => (
          //     <div className={styles.flexTableCell}>{record?.profitCenter || "-"}</div>
          //   ),
          // },
        ]
      : []),
    ...(isBMManager || (isIncentiveTeam && isCityLead)
      ? [
          {
            title: (
              <div className={styles.flexTableCell}>
                <span>{STRING_CONSTANTS.LEAD_COLLECTION}</span>
                {hideLeadCollectionFields ? (
                  <PlusCircleTwoTone
                    className={`${styles.plusMinusIcon} ${styles.paddingRightSmall}`}
                    twoToneColor={colors["primary-400"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      setHideLeadCollectionFields(!hideLeadCollectionFields);
                    }}
                  />
                ) : (
                  <MinusCircleTwoTone
                    className={`${styles.plusMinusIcon} ${styles.paddingRightSmall}`}
                    twoToneColor={colors["primary-400"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      setHideLeadCollectionFields(!hideLeadCollectionFields);
                    }}
                  />
                )}
              </div>
            ),
            dataIndex: "agentCollection",
            key: "agentCollection",
            width: 100,
            sorter: true,
            render: (text: string, record: IIncentive) => (
              <div className={styles.flexTableCell}>{record?.agentCollection || "-"}</div>
            ),
          },
          {
            title: STRING_CONSTANTS.LEAD_NET_REVENUE,
            dataIndex: "agentRevenue",
            key: "agentRevenue",
            width: 100,
            sorter: true,
            hidden: hideLeadCollectionFields,
            render: (text: string, record: IIncentive) => (
              <div className={styles.flexTableCell}>
                <div>{record?.agentRevenue || "-"}</div>
                <ArTooltip
                  heading=""
                  customTitle={
                    <>
                      <div>{STRING_CONSTANTS.REVENUE_WITH_DOCKING}</div>
                      <div> {record?.agentDocketedRevenue || "-"}</div>
                    </>
                  }
                >
                  <div className={styles.iconsPadding}>
                    <InfoCircleOutlined />
                  </div>
                </ArTooltip>
              </div>
            ),
          },
          // {
          //   title: STRING_CONSTANTS.LEAD_EFFECTIVE_CTC,
          //   dataIndex: "agent",
          //   key: "agent",
          //   width: 100,
          //   sorter: true,
          //   hidden: hideLeadCollectionFields,
          //   render: (text: string, record: IIncentive) => (
          //     <div className={styles.flexTableCell}>{record?.profitCenter || "-"}</div>
          //   ),
          // },
          {
            title: STRING_CONSTANTS.TEAM_PROFIT,
            dataIndex: "teamProfit",
            key: "teamProfit",
            width: 100,
            sorter: true,
            render: (text: string, record: IIncentive) => (
              <div className={styles.flexTableCell}>{record?.teamProfit || "-"}</div>
            ),
          },

          {
            title: STRING_CONSTANTS.TEAM_PL_MARGIN,
            dataIndex: "teamPLMargin",
            key: "teamPLMargin",
            width: 100,
            sorter: true,
            render: (text: string, record: IIncentive) => (
              <div className={styles.flexTableCell}>{record?.teamPLMargin || "-"}</div>
            ),
          },
        ]
      : []),
    {
      title: STRING_CONSTANTS.ACCURAL_ELIGIBILITY,
      dataIndex: "accrualEligibility",
      key: "accrualEligibility",
      width: 100,
      sorter: true,
      render: (text: string, record: IIncentive, index: number) => (
        <>
          {index === 0 ? (
            "-"
          ) : (
            <div>
              {record?.accrualEligibility ? (
                <div className={styles.flexTableCell}>
                  <div className={styles.greenText}>{STRING_CONSTANTS.ELIGIBLE}</div>
                  <ArTooltip
                    heading=""
                    customTitle={
                      <>
                        <div>{STRING_CONSTANTS.TEAM_THRESHOLD}</div>
                        <div> {record?.teamThreshold || "-"}</div>
                        <div>{STRING_CONSTANTS.INDIVIDUAL_THRESHOLD}</div>
                        <div> {record?.agentThreshold || "-"}</div>
                      </>
                    }
                  >
                    <div className={styles.iconsPadding}>
                      <InfoCircleOutlined />
                    </div>
                  </ArTooltip>
                </div>
              ) : (
                <div className={styles.flexTableCell}>
                  <div className={styles.redText}>{STRING_CONSTANTS.NOT_ELIGIBLE}</div>
                  <ArTooltip
                    heading=""
                    customTitle={
                      <>
                        <div>{STRING_CONSTANTS.TEAM_THRESHOLD}</div>
                        <div> {record?.teamThreshold || "-"}</div>
                        <div>{STRING_CONSTANTS.INDIVIDUAL_THRESHOLD}</div>
                        <div> {record?.agentThreshold || "-"}</div>
                      </>
                    }
                  >
                    <div className={styles.iconsPadding}>
                      <InfoCircleOutlined />
                    </div>
                  </ArTooltip>
                </div>
              )}
            </div>
          )}
        </>
      ),
    },
    {
      title: STRING_CONSTANTS.PAYABLE_ELIGIBILITY,
      dataIndex: "payableEligibility",
      key: "payableEligibility",
      width: 100,
      sorter: true,
      render: (text: string, record: IIncentive, index: number) => (
        <>
          {index === 0 ? (
            "-"
          ) : (
            <div>
              {record?.payableEligibility ? (
                <div className={styles.flexTableCell}>
                  <div className={styles.greenText}>{STRING_CONSTANTS.ELIGIBLE}</div>
                  <ArTooltip
                    heading=""
                    customTitle={
                      <>
                        <div>{STRING_CONSTANTS.TEAM_THRESHOLD}</div>
                        <div> {record?.teamThreshold || "-"}</div>
                        <div>{STRING_CONSTANTS.INDIVIDUAL_THRESHOLD}</div>
                        <div> {record?.agentThreshold || "-"}</div>
                      </>
                    }
                  >
                    <div className={styles.iconsPadding}>
                      <InfoCircleOutlined />
                    </div>
                  </ArTooltip>
                </div>
              ) : (
                <div className={styles.flexTableCell}>
                  <div className={styles.redText}>{STRING_CONSTANTS.NOT_ELIGIBLE}</div>
                  <ArTooltip
                    heading=""
                    customTitle={
                      <>
                        <div>{STRING_CONSTANTS.TEAM_THRESHOLD}</div>
                        <div> {record?.teamThreshold || "-"}</div>
                        <div>{STRING_CONSTANTS.INDIVIDUAL_THRESHOLD}</div>
                        <div> {record?.agentThreshold || "-"}</div>
                      </>
                    }
                  >
                    <div className={styles.iconsPadding}>
                      <InfoCircleOutlined />
                    </div>
                  </ArTooltip>
                </div>
              )}
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className={styles.flexTableCell}>
          {STRING_CONSTANTS.ACCURED_INCENTIVES}{" "}
          <ArTooltip
            heading=""
            customTitle={
              <>
                <div>{STRING_CONSTANTS.ACCURED_INCENTIVE_TOOLTIP}</div>
              </>
            }
          >
            <div className={styles.iconsPadding}>
              <InfoCircleOutlined />
            </div>
          </ArTooltip>
        </div>
      ),
      dataIndex: "accruedIncentive",
      key: "accruedIncentive",
      width: 100,
      sorter: true,
      showSorterTooltip: false,
      render: (text: string, record: IIncentive) => (
        <div className={styles.flexTableCell}>
          {isVisible ? record?.accruedIncentive || "-" : <div>{STRING_CONSTANTS.RUPEE_SYMBOL}...</div>}
        </div>
      ),
    },
    {
      title: STRING_CONSTANTS.EMPLOYEE_ID,
      dataIndex: "employeeId",
      key: "employeeId",
      width: 100,
      render: (text: string, record: IIncentive) => (
        <div className={styles.flexTableCell}>{record?.employeeId || "-"}</div>
      ),
    },
    {
      title: STRING_CONSTANTS.EMPLOYMENT_DURATION,
      dataIndex: "employmentDuration",
      key: "employmentDuration",
      width: 100,
      render: (text: string, record: IIncentive) => (
        <div className={styles.flexTableCell}>{record?.employmentDuration || "-"}</div>
      ),
    },
    ...(isBMLead || (isIncentiveTeam && isAgent)
      ? [
          {
            title: STRING_CONSTANTS.EMPLOYEE_STATUS,
            dataIndex: "employeeStatus",
            key: "employeeStatus",
            width: 100,
            sorter: true,
            render: (text: string, record: IIncentive) => (
              <div className={styles.flexTableCell}>{record?.employeeStatus || "-"}</div>
            ),
          },
        ]
      : []),
    {
      title: STRING_CONSTANTS.PAID_PAYABLE_INCENTIVES,
      dataIndex: "paidIncentive",
      key: "paidIncentive",
      width: 100,
      fixed: TABLE_CONSTANTS.RIGHT,
      sorter: true,
      render: (text: string, record: IIncentive, index: number) => (
        <div className={styles.flexTableCell}>
          {isVisible ? (
            <div className="fullWidth">
              <div>
                {convertToRupee(Number(record?.paidIncentive))} / {convertToRupee(Number(record?.payableIncentive))}
              </div>
              {showSummary && index === 0 ? null : (
                <ArTooltip
                  heading=""
                  customTitle={
                    <>
                      <div>{STRING_CONSTANTS.BALANCE_INCENTIVES}</div>
                      <div>
                        {convertToRupee(Number(record?.payableIncentive || 0) - Number(record?.paidIncentive || 0))}
                      </div>
                    </>
                  }
                >
                  <ArProgress
                    success={{
                      percent: (Number(record?.paidIncentive || 0) / Number(record?.payableIncentive || 1)) * 100,
                      strokeColor: colors["emerald-150"],
                    }}
                    trailColor={colors["primary-100"]}
                  />
                </ArTooltip>
              )}
            </div>
          ) : (
            <div>{STRING_CONSTANTS.RUPEE_SYMBOL}...</div>
          )}

          {showSummary && index === 0 ? null : <IncentiveDropdown incentive={record} />}
        </div>
      ),
    },
    // @ts-ignore
  ].filter((item) => !item.hidden);

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>
  ) => {
    const order = sorter?.order === "descend" ? "-1" : sorter?.order === "ascend" ? "1" : "";

    setPageNo(1);
    setSortData({ field: sorter?.field?.toString() || "", order });
  };

  const dataSource = incentivesList?.data?.incentiveList || [];
  const summaryDataSource = incentivesList?.data?.summaryRow || [];

  return (
    <>
      {showHeading ? (
        <div className="display-flex-space-between pb-8">
          <ArBreadCrumb className={styles.breadCrumb}>
            <ArBreadCrumbItem className={styles.breadCrumbItem}>
              {isBMManager || isAgent
                ? STRING_CONSTANTS.AGENT_INCENTIVES
                : isBMLead || isCityLead
                ? STRING_CONSTANTS.CITY_LEAD_INCENTIVES
                : null}{" "}
              ({incentivesList?.data?.paginationParams?.count})
            </ArBreadCrumbItem>
          </ArBreadCrumb>
          <div>
            <ArSegmented
              onChange={() => setIsVisible(!isVisible)}
              defaultValue="hide"
              value={isVisible ? "show" : "hide"}
              size="large"
              options={[
                {
                  label: `${STRING_CONSTANTS.HIDE}`,
                  value: "hide",
                  icon: <EyeInvisibleOutlined />,
                },
                {
                  label: `${STRING_CONSTANTS.SHOW}`,
                  value: "show",
                  icon: <EyeOutlined />,
                },
              ]}
            />
          </div>
        </div>
      ) : null}
      <div className={`${showSummary ? styles.incentiveTableContainer : ""}`}>
        <ArTable
          columns={columns || []}
          dataSource={showSummary ? [...summaryDataSource, ...dataSource] : dataSource}
          pagination={false}
          scroll={{ x: columns.length * 200, y: window.innerHeight - 300 }}
          loading={isLoading || isFetching}
          onChange={(pagination, filters, sorter) => handleChange(pagination, filters, sorter as SorterResult<any>)}
        />
      </div>
      {showPagination ? (
        <div className={styles.paginationContainer}>
          <ArPagination
            current={pageNo}
            onChange={(page) => {
              setPageNo(page);
            }}
            pageSize={pageLimit}
            total={incentivesList?.data?.paginationParams?.count || pageLimit}
            showSizeChanger={false}
          />
        </div>
      ) : null}
    </>
  );
};

export default IncentiveTable;
