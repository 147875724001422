import { ArButton, ArDropdown, ButtonTypes } from "@anarock/ar-common-react";
import { DownloadOutlined, DownOutlined, ImportOutlined } from "@ant-design/icons";
import { useAppSelector } from "app/hooks";
import { useIncentiveListingFiltersQuery } from "app/services/incentives";
import { ISelectedFilters } from "app/services/listingPageApi";
import { useFiltersWithQuery } from "app/utils/useFiltersHook";
import { useGenerateBrochureHook } from "app/utils/useGenerateBrochureHook";
import GenericConfirmationModal from "components/appModals/genericConfirmationModal";
import { ListingFilterBar } from "components/filterBar";
import ImportSheetModal from "components/ImportSheetModal";
import { SheetTypeEnum } from "components/ImportSheetModal/index.interface";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { USER_ROLE_NAME } from "constants/Taboptions";
import { selectAuthUser } from "features/authentication/authSlice";
import React, { useMemo, useState } from "react";

import IncentiveTable from "./components/IncentiveTable";
import styles from "./index.module.scss";

const IncentivesListing = () => {
  const user = useAppSelector(selectAuthUser);

  const { selectedFilters } = useFiltersWithQuery<ISelectedFilters>();
  const [openUploadIncentiveModal, setOpenUploadIncentiveModal] = useState(false);
  const [openUploadTdsConfirmationModal, setOpenUploadTdsConfirmationModal] = useState(false);
  const [openUploadEffectiveCtcModal, setOpenUploadEffectiveCtcModal] = useState(false);
  const [openPreDownloadIncentiveModal, setOpenPreDownloadIncentiveModal] = useState(false);
  const [openPreDownloadTDSModal, setOpenPreDownloadTDSModal] = useState(false);
  const [openPreDownloadBalancePayableModal, setOpenPreDownloadBalancePayableModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const isIncentiveTeam = useMemo(() => {
    return user?.subRoles?.includes(USER_ROLE_NAME.INCENTIVE_TEAM);
  }, [user]);

  const { generateIncentiveReportExcelSheet, generatePendingTDSExcelSheet, generateBalancePayableIncentiveExcelSheet } =
    useGenerateBrochureHook();

  const { data: filtersData, isLoading } = useIncentiveListingFiltersQuery(
    {
      selectedFilters: selectedFilters,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const filters = useMemo(() => filtersData?.data?.filters, [filtersData?.data?.filters]);
  const moreFilters = useMemo(() => filtersData?.data?.moreFilters, [filtersData?.data?.moreFilters]);

  const items = [
    {
      key: "0",
      label: (
        <p
          onClick={() => {
            setDropdownOpen(false);
            setOpenPreDownloadIncentiveModal(true);
          }}
        >
          <DownloadOutlined className="pr-8" />
          {STRING_CONSTANTS.DOWNLOAD_INCENTIVE_REPORT}
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <p
          onClick={() => {
            setDropdownOpen(false);
            setOpenPreDownloadBalancePayableModal(true);
          }}
        >
          <DownloadOutlined className="pr-8" />
          {STRING_CONSTANTS.DOWNLOAD_BALANCE_PAYABLE_INCENTIVE_REPORT}
        </p>
      ),
    },
    {
      key: "1",
      label: (
        <p
          onClick={() => {
            setOpenUploadIncentiveModal(true);
            setDropdownOpen(false);
          }}
        >
          <ImportOutlined className="pr-8" />
          {STRING_CONSTANTS.IMPORT_INCENTIVE_PAYOUT}
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p
          onClick={() => {
            setDropdownOpen(false);
            setOpenPreDownloadTDSModal(true);
          }}
        >
          <DownloadOutlined className="pr-8" />
          {STRING_CONSTANTS.DOWNLOAD_PENDING_TDS_REPORT}
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <p
          onClick={() => {
            setDropdownOpen(false);
            setOpenUploadTdsConfirmationModal(true);
          }}
        >
          <ImportOutlined className="pr-8" />
          {STRING_CONSTANTS.IMPORT_TDS_CONFIRMATION_SHEET}
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <p
          onClick={() => {
            setDropdownOpen(false);
            setOpenUploadEffectiveCtcModal(true);
          }}
        >
          <ImportOutlined className="pr-8" />
          {STRING_CONSTANTS.UPLOAD_EFFECTIVE_CTC}
        </p>
      ),
    },
  ];

  return (
    <div>
      <ListingFilterBar
        filters={filters || []}
        moreFilters={moreFilters || []}
        loading={isLoading}
        button={
          <>
            {isIncentiveTeam ? (
              <div className="pr-24">
                <ArButton type={ButtonTypes.Outlined} onClick={() => setOpenPreDownloadIncentiveModal(true)}>
                  <DownloadOutlined className="pr-8" />
                  {STRING_CONSTANTS.DOWNLOAD_INCENTIVE_REPORT}
                </ArButton>
                <ArDropdown
                  open={dropdownOpen}
                  menu={{ items }}
                  trigger={["click"]}
                  className={styles.dropdown}
                  overlayClassName={" dropdownOverlay"}
                  onOpenChange={() => setDropdownOpen((prevVal) => !prevVal)}
                >
                  <ArButton type={ButtonTypes.Outlined} className={styles.dropDownBtn}>
                    <DownOutlined />
                  </ArButton>
                </ArDropdown>
              </div>
            ) : null}
          </>
        }
      />

      {openUploadIncentiveModal ? (
        <ImportSheetModal
          type={SheetTypeEnum.PAYOUT}
          open={openUploadIncentiveModal}
          onCancel={() => setOpenUploadIncentiveModal(false)}
          closeModal={() => setOpenUploadIncentiveModal(false)}
        />
      ) : null}
      {openUploadTdsConfirmationModal ? (
        <ImportSheetModal
          type={SheetTypeEnum.TDS_CONFIRMATION}
          open={openUploadTdsConfirmationModal}
          onCancel={() => setOpenUploadTdsConfirmationModal(false)}
          closeModal={() => setOpenUploadTdsConfirmationModal(false)}
        />
      ) : null}
      {openUploadEffectiveCtcModal ? (
        <ImportSheetModal
          type={SheetTypeEnum.EFFECTIVE_CTC}
          open={openUploadEffectiveCtcModal}
          onCancel={() => setOpenUploadEffectiveCtcModal(false)}
          closeModal={() => setOpenUploadEffectiveCtcModal(false)}
        />
      ) : null}
      <div className={styles.tableContainer}>
        <IncentiveTable />
      </div>
      {openPreDownloadIncentiveModal ? (
        <GenericConfirmationModal
          body={<div className="pb-20">{STRING_CONSTANTS.DOWNLOAD_REPORT_BODY}</div>}
          open={openPreDownloadIncentiveModal}
          onCancel={() => setOpenPreDownloadIncentiveModal(false)}
          closeModal={() => setOpenPreDownloadIncentiveModal(false)}
          title={STRING_CONSTANTS.DOWNLOAD_CONFIRMATION}
          submitText={STRING_CONSTANTS.DOWNLOAD_REPORT}
          onSubmit={() => {
            setOpenPreDownloadIncentiveModal(false);
            generateIncentiveReportExcelSheet();
          }}
          cancelText={STRING_CONSTANTS.GO_BACK}
        ></GenericConfirmationModal>
      ) : null}

      {openPreDownloadTDSModal ? (
        <GenericConfirmationModal
          body={<div className="pb-20">{STRING_CONSTANTS.DOWNLOAD_REPORT_BODY_WITHOUT_FILTER}</div>}
          open={openPreDownloadTDSModal}
          onCancel={() => setOpenPreDownloadTDSModal(false)}
          closeModal={() => setOpenPreDownloadTDSModal(false)}
          title={STRING_CONSTANTS.DOWNLOAD_CONFIRMATION}
          submitText={STRING_CONSTANTS.DOWNLOAD_REPORT}
          onSubmit={() => {
            setOpenPreDownloadTDSModal(false);
            generatePendingTDSExcelSheet();
          }}
          cancelText={STRING_CONSTANTS.GO_BACK}
        ></GenericConfirmationModal>
      ) : null}

      {openPreDownloadBalancePayableModal ? (
        <GenericConfirmationModal
          body={<div className="pb-20">{STRING_CONSTANTS.DOWNLOAD_REPORT_BODY_WITHOUT_FILTER}</div>}
          open={openPreDownloadBalancePayableModal}
          onCancel={() => setOpenPreDownloadBalancePayableModal(false)}
          closeModal={() => setOpenPreDownloadBalancePayableModal(false)}
          title={STRING_CONSTANTS.DOWNLOAD_CONFIRMATION}
          submitText={STRING_CONSTANTS.DOWNLOAD_REPORT}
          onSubmit={() => {
            setOpenPreDownloadBalancePayableModal(false);
            generateBalancePayableIncentiveExcelSheet();
          }}
          cancelText={STRING_CONSTANTS.GO_BACK}
        ></GenericConfirmationModal>
      ) : null}
    </div>
  );
};

export default IncentivesListing;
