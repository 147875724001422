import { ArButton, ArModal, ArTable } from "@anarock/ar-common-react";
import ArTooltip from "@anarock/ar-common-react/dist/ui-components/ar-tooltip";
import { InfoCircleOutlined } from "@ant-design/icons";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { ITableObj } from "app/services/analytics";
import { useFetchCollectionHistoryTableQuery } from "app/services/incentives";
import { getValueOrDash } from "app/utils/helperFunctions";
import { TABLE_CONSTANTS } from "constants/helperStrings";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useState } from "react";

import styles from "../../../analytics/wip/components/WIPInvoiceDetails/index.module.scss";
import EmployeeCollectionHistoryModalProps from "./index.interface";

const EmployeeCollectionHistoryModal = ({
  open,
  closeModal,
  incentive,
  ...props
}: EmployeeCollectionHistoryModalProps) => {
  const [sortData, setSortData] = useState<{
    field: string | number;
    order: string;
  }>({
    field: "",
    order: "",
  });

  const agentId = typeof incentive?.agentId === "string" ? [incentive?.agentId] : incentive?.agentId;
  const profitCenter =
    typeof incentive?.profitCenter === "string" ? [incentive?.profitCenter] : incentive?.profitCenter;

  const { data, isLoading } = useFetchCollectionHistoryTableQuery(
    { agentId: agentId, fy: incentive?.fyKey, profitCenter: profitCenter, sort: sortData },
    {
      refetchOnMountOrArgChange: true,
      skip: !open || !incentive?.agentId || !incentive?.fyKey || !incentive?.profitCenter,
    }
  );

  const getTableHeaders = () => {
    const headers = data?.data?.headers;

    if (!headers) return [];

    return Object.keys(headers).map((el, idx) => {
      if (el === "sno") {
        return {
          title: headers[el],
          dataIndex: el,
          key: el,
          width: 1.3,
          bordered: true,
          fixed: TABLE_CONSTANTS.LEFT,
          render: (text: number, record: ITableObj) => (
            <p className={record?.sno === "#" ? "bold" : undefined}>{getValueOrDash(text)}</p>
          ),
        };
      } else if (el === "feeShare") {
        return {
          title: headers[el],
          dataIndex: el,
          key: el,
          bordered: true,
          width: 5,
          sorter: true,
          render: (text: number, record: ITableObj) => (
            <p className={record?.sno === "#" ? "bold" : undefined}>
              <div className={styles.flexTableCell}>
                <div>{getValueOrDash(text)}</div>
                {record?.sno === "#" ? null : (
                  <ArTooltip
                    heading=""
                    customTitle={
                      <>
                        <div>{STRING_CONSTANTS.THIRD_PARTY_SHARE}</div>
                        <div> {record?.thirdPartyCollection || "-"}</div>
                        <div>{STRING_CONSTANTS.INTER_DEPARTMENT_SHARE}</div>
                        <div> {record?.interDptmtFeeShare || "-"}</div>
                      </>
                    }
                  >
                    <div className={styles.iconsPadding}>
                      <InfoCircleOutlined />
                    </div>
                  </ArTooltip>
                )}
              </div>
            </p>
          ),
        };
      } else if (el === "days") {
        return {
          title: (
            <div className={styles.flexTableCell}>
              {headers[el]}{" "}
              <ArTooltip
                heading=""
                customTitle={
                  <>
                    <div>{STRING_CONSTANTS.CALCULATED_FROM_THE_DUE_DATE}</div>
                  </>
                }
              >
                <div className={styles.iconsPadding}>
                  <InfoCircleOutlined />
                </div>
              </ArTooltip>
            </div>
          ),
          dataIndex: el,
          key: el,
          bordered: true,
          width: 5,
          sorter: true,
          showSorterTooltip: false,
          render: (text: number, record: ITableObj) => (
            <p className={record?.sno === "#" ? "bold" : undefined}>
              <div className={styles.flexTableCell}>
                {getValueOrDash(text)}
                {record?.sno === "#" ? null : (
                  <ArTooltip
                    heading=""
                    customTitle={
                      <>
                        {text < 60 ? (
                          <>
                            <div>{"100% fee considered"}</div> <div>{"Collection in days < 60"}</div>
                          </>
                        ) : text >= 60 && text < 120 ? (
                          <>
                            <div>{"95% fee considered"}</div> <div>{"60 >= Collection in days < 120"}</div>
                          </>
                        ) : text >= 120 ? (
                          <>
                            <div>{"90% fee considered"}</div> <div>{"120 >= Collection in days"}</div>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    }
                  >
                    <div className={styles.iconsPadding}>
                      <InfoCircleOutlined />
                    </div>
                  </ArTooltip>
                )}
              </div>
            </p>
          ),
        };
      } else if (el === "allocatedRevenue" || el === "employeeCode") {
        return {
          title: headers[el],
          dataIndex: el,
          key: el,
          bordered: true,
          width: 5,
          sorter: true,
          render: (text: number, record: ITableObj) => (
            <p className={record?.sno === "#" ? "bold" : undefined}>{getValueOrDash(text)}</p>
          ),
        };
      }

      return {
        title: headers[el],
        dataIndex: el,
        key: el,
        bordered: true,
        width: 5,
        sorter: true,
        render: (text: string | number, record: ITableObj) => (
          <p className={record?.sno === "#" ? "bold" : undefined}>{getValueOrDash(text?.toLocaleString("en-IN"))}</p>
        ),
      };
    });
  };

  const rows = data?.data?.rows || [];
  const summaryDataSource = data?.data?.summaryRow || [];

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>
  ) => {
    const order = sorter?.order === "descend" ? "-1" : sorter?.order === "ascend" ? "1" : "";

    setSortData({ field: sorter?.field?.toString() || "", order });
  };

  return (
    <ArModal
      open={open}
      title={`${STRING_CONSTANTS.COLLECTION_HISTORY} ${STRING_CONSTANTS.FOR} ${STRING_CONSTANTS.PAYABLE_INCENTIVES}`}
      width={946}
      showBorderForHeader
      footer={<ArButton onClick={closeModal}>{STRING_CONSTANTS.UNDERSTOOD}</ArButton>}
      onCancel={closeModal}
      {...props}
    >
      <div className={`${styles.invoiceDetails} mt-24`}>
        <>
          <ArTable
            columns={getTableHeaders()}
            dataSource={[...summaryDataSource, ...rows]}
            pagination={false}
            scroll={{
              y: 400,
              x: 1500,
              scrollToFirstRowOnChange: true,
            }}
            loading={isLoading}
            onChange={(pagination, filters, sorter) => handleChange(pagination, filters, sorter as SorterResult<any>)}
          />
        </>
      </div>
    </ArModal>
  );
};

export default EmployeeCollectionHistoryModal;
