import { ArDropdown } from "@anarock/ar-common-react";
import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { IIncentive } from "app/services/incentives";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useState } from "react";

import EmployeeCollectionHistoryModal from "../EmployeeCollectionhistory";
import EmployeePaymentHistoryModal from "../EmployeePaymentHistory";
import EmployeeRevenueHistoryModal from "../EmployeeRevenueHistory";

const IncentiveDropdown = ({ incentive }: { incentive: IIncentive }) => {
  const [open, setOpen] = useState(false);
  const [openCollectionHistoryModal, setOpenCollectionHistoryModal] = useState(false);
  const [openPaymentHistoryModal, setOpenPaymentHistoryModal] = useState(false);
  const [openRevenueHistoryModal, setOpenRevenueHistoryModal] = useState(false);

  const items = [
    {
      key: "0",
      label: (
        <p
          onClick={() => {
            setOpen(false);
            setOpenRevenueHistoryModal(true);
          }}
        >
          <EyeOutlined className="pr-8" />
          {STRING_CONSTANTS.VIEW_REVENUE_HISTORY}
        </p>
      ),
    },
    {
      key: "1",
      label: (
        <p
          onClick={() => {
            setOpen(false);
            setOpenCollectionHistoryModal(true);
          }}
        >
          <EyeOutlined className="pr-8" />
          {STRING_CONSTANTS.VIEW_COLLECTION_HISTORY}
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p
          onClick={() => {
            setOpen(false);
            setOpenPaymentHistoryModal(true);
          }}
        >
          <EyeOutlined className="pr-8" />
          {STRING_CONSTANTS.VIEW_INCENTIVE_PAYMENT_HISTORY}
        </p>
      ),
    },
  ];

  return (
    <>
      <ArDropdown
        menu={{
          items,
        }}
        open={open}
        trigger={["click"]}
        className={"dropdownOverlay"}
        overlayClassName={" dropdownOverlay"}
        onOpenChange={setOpen}
      >
        <div>
          <MoreOutlined className="color-grey-600 iconSize24 zIndex100 icon-hover-bg" />
        </div>
      </ArDropdown>
      <EmployeeCollectionHistoryModal
        open={openCollectionHistoryModal}
        onCancel={() => setOpenCollectionHistoryModal(false)}
        closeModal={() => setOpenCollectionHistoryModal(false)}
        incentive={incentive}
      />
      <EmployeePaymentHistoryModal
        open={openPaymentHistoryModal}
        onCancel={() => setOpenPaymentHistoryModal(false)}
        closeModal={() => setOpenPaymentHistoryModal(false)}
        incentive={incentive}
      />
      <EmployeeRevenueHistoryModal
        open={openRevenueHistoryModal}
        onCancel={() => setOpenRevenueHistoryModal(false)}
        closeModal={() => setOpenRevenueHistoryModal(false)}
        incentive={incentive}
      />
    </>
  );
};

export default IncentiveDropdown;
