import { ArDropdown } from "@anarock/ar-common-react";
import { DownloadOutlined, EditOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { useAppSelector } from "app/hooks";
import { ITableObj } from "app/services/analytics";
import { openURLInNewTab } from "app/utils/helperFunctions";
import { ALLOCATION_STATUS } from "constants/index";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { USER_ROLE_NAME } from "constants/Taboptions";
import { selectAuthUser } from "features/authentication/authSlice";
import React, { useMemo, useState } from "react";

import IrfRevenueAllocationHistoryModal from "../AllocationHistoryTableModal";
import { AllocateRevenueFormModal } from "../RevenueAllocationForm";

export const AllocationDropdown = ({ status, irf }: { status: string; irf: ITableObj }) => {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [historyModal, setHistoryModal] = useState<{ open: boolean; irf?: ITableObj }>({ open: false, irf: undefined });

  const { irfId, invoiceURL: url, clientName, irfProjectId, profitCenter } = irf;

  const user = useAppSelector(selectAuthUser);

  const isBMLead = useMemo(() => {
    return (
      user?.subRoles?.includes(USER_ROLE_NAME.BRAND_MANAGER_LEAD) ||
      user?.subRoles?.includes(USER_ROLE_NAME.BRAND_MANAGER_LEAD_MARKER)
    );
  }, [user]);

  const handleDownload = () => {
    const downloadLink = document.createElement("a");

    downloadLink.href = url as string;
    downloadLink.download = clientName as string;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const items = [
    ...(isBMLead && status === ALLOCATION_STATUS.ALLOCATION_PENDING
      ? [
          {
            key: "0",
            label: (
              <p
                onClick={() => {
                  setModalOpen(true);
                  setOpen(false);
                }}
              >
                <EditOutlined className="pr-8" />
                {STRING_CONSTANTS.EDIT_REVENUE_ALLOCATION}
              </p>
            ),
          },
        ]
      : []),
    ...(!isBMLead || status === ALLOCATION_STATUS.FULLY_ALLOCATED
      ? [
          {
            key: "1",
            label: (
              <p
                onClick={() => {
                  setOpen(false);
                  setHistoryModal({ open: true, irf: irf });
                }}
              >
                <EyeOutlined className="pr-8" />
                {STRING_CONSTANTS.VIEW_REVENUE_ALLOCATION}
              </p>
            ),
          },
        ]
      : []),
    ...(isBMLead && status === ALLOCATION_STATUS.POOLED_ALLOCATION
      ? [
          {
            key: "2",
            label: (
              <p
                onClick={() => {
                  setModalOpen(true);
                  setOpen(false);
                }}
              >
                <EyeOutlined className="pr-8" />
                {STRING_CONSTANTS.ALLOCATE_POOLED_REVENUE}
              </p>
            ),
          },
        ]
      : []),
    ...(isBMLead
      ? [
          {
            key: "3",
            label: (
              <p
                onClick={() => {
                  setOpen(false);
                  openURLInNewTab(`${APP_ROUTES.INVOICES}/${irfId}/${APP_ROUTES.WIP_SUMMARY}`);
                }}
              >
                <EyeOutlined className="pr-8" />
                {STRING_CONSTANTS.VIEW_INVOICE_REQUEST}
              </p>
            ),
          },
        ]
      : []),
    ...(url
      ? [
          {
            key: "4",
            label: (
              <p
                onClick={() => {
                  setOpen(false);
                  handleDownload();
                }}
              >
                <DownloadOutlined className="pr-8" />
                {STRING_CONSTANTS.VIEW_INVOICE}
              </p>
            ),
          },
        ]
      : []),
  ];

  const handleClose = () => {
    setHistoryModal({ open: false, irf: undefined });
  };

  return (
    <>
      {modalOpen ? (
        <AllocateRevenueFormModal
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          profitCenter={profitCenter as string}
          irfProjectId={irfProjectId as string}
        />
      ) : null}
      <ArDropdown
        menu={{
          items,
        }}
        open={open}
        trigger={["click"]}
        className={"dropdownOverlay"}
        overlayClassName={" dropdownOverlay"}
        onOpenChange={setOpen}
      >
        <div>
          <MoreOutlined className="color-grey-600 iconSize24 zIndex100 icon-hover-bg" />
        </div>
      </ArDropdown>
      {historyModal?.open ? (
        <IrfRevenueAllocationHistoryModal
          open={historyModal?.open}
          irf={historyModal?.irf}
          closeModal={handleClose}
          onCancel={handleClose}
        />
      ) : null}
    </>
  );
};
