import { ArModalProps } from "@anarock/ar-common-react";

export enum SheetTypeEnum {
  REVENUE = "revenue",
  INVOICE = "invoice",
  COLLECTION = "collection",
  PAYOUT = "payout",
  TDS_CONFIRMATION = "tdsConfirmation",
  EFFECTIVE_CTC = "effectiveCtc",
}
export interface IWipImportSheetModalProps extends ArModalProps {
  type?: SheetTypeEnum;
  closeModal: () => void;
  replace?: boolean;
}
