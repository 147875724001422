import { ArShopCarousel, ShopFeatureTags } from "@anarock/ar-common-react";
import ArTooltip from "@anarock/ar-common-react/dist/ui-components/ar-tooltip";
import { CopyOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { getValueOrDash } from "app/utils/helperFunctions";
import { useToastService } from "components/toastService";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import React from "react";

import { ShopFeatureProps, ShopInfoProps } from "./index.interface";
import styles from "./index.module.scss";

export const ShopFeature: React.FC<ShopFeatureProps> = ({
  label,
  value,
  name,
  className = "",
  allowCopyToastMessage,
  allowTooltip,
  toolTipValue,
}) => {
  const { successToast } = useToastService();

  const copyValue = () => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(value);
      successToast(allowCopyToastMessage || TOAST_MESSAGES.MESSAGE_COPIED);
    }
  };

  return (
    <ArTooltip heading={name || value}>
      <div className={styles.shopFeature}>
        <h5>{label}</h5>
        <div className="d-flex">
          <p className={styles[className]}>{getValueOrDash(value)}</p>
          {allowCopyToastMessage && <CopyOutlined className={styles.copyIcon} onClick={copyValue} />}
          {allowTooltip ? toolTipValue : null}
        </div>
      </div>
    </ArTooltip>
  );
};

const BasicImageInfoCard: React.FC<ShopInfoProps> = ({
  header,
  address,
  images,
  shopFeatures,
  shopNo,
  shopStatus,
  features,
}) => {
  return (
    <div className={styles.shopInfoCardWrapper}>
      {header}
      <div className={styles.shopImageInfoContainer}>
        <div className={styles.shopInfo}>
          <h5>{shopNo}</h5>
          <p> {address}</p>
        </div>
        <div className={styles.carousel}>
          <ArShopCarousel images={images.length < 3 ? [...images, ...images, ...images] : images} />
        </div>
        {features && features?.length ? (
          <div className="pb-8">
            <ShopFeatureTags features={features} />
          </div>
        ) : null}

        {shopStatus && (
          <div className={styles.shopStatus}>
            <div className={styles.innerBox}>
              <h5>{shopStatus.shopSurveyor}</h5>
              <p>{shopStatus.updateStatus}</p>
            </div>
            {shopStatus.shopAction}
          </div>
        )}
        <div className={styles.shopFeatures}>
          {shopFeatures.map((feature, index) => {
            if (index != shopFeatures.length - 1) {
              return (
                <React.Fragment key={index}>
                  <ShopFeature {...feature} />
                  <Divider type="vertical" />
                </React.Fragment>
              );
            } else {
              return <ShopFeature key={index} {...feature} />;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default BasicImageInfoCard;
